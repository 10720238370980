import React, { useCallback, useMemo, useState } from 'react';
import {
  Grid,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
  Typography,
  Button,
  colors,
  styled,
  Tooltip,
} from '@mui/material';
import { ClearAll, Menu as MenuIcon } from '@mui/icons-material';
import { Settings } from 'react-feather';
import { NavLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  HeaderVehicleAlertList,
  HeaderMapVersionAlert,
  HeaderUserMenu,
} from '@components/common/molecules';
import ProjectEnvironmentDialog from '../ProjectEnvrionmentDialog';
import { useTranslation } from 'react-i18next';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useFilteredRoutes } from '@routes/states';
import MaintenanceInfo from '../MaintenanceInfo';
import { useSnackbar } from 'notistack';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { hasAlertAtom } from '@data/notification';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

type HeaderProps = {
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const Header: React.FC<HeaderProps> = ({ onDrawerToggle }: HeaderProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const project = useRecoilValue(projectAtom);
  const environment = useRecoilValue(environmentAtom);
  const routes = useFilteredRoutes();
  const { closeSnackbar } = useSnackbar();
  const setHasAlert = useSetRecoilState(hasAlertAtom);

  const currentRoute = useMemo(() => {
    const firstPathName = location.pathname
      .substring(1, location.pathname.length)
      .split('/')[2];
    const currentRoute = routes.find((route) => {
      const routePath = route.path.split('/')[0];
      return routePath === firstPathName;
    });
    return currentRoute;
  }, [location.pathname, routes]);

  const title = useMemo(() => {
    if (isNullOrUndefined(currentRoute)) return null;
    if (currentRoute.id === 'vehicle_condition') {
      if (location.pathname.includes('maintenance')) {
        return t('vehicle_condition.list.condition');
      } else if (location.pathname.includes('parameter')) {
        return t('vehicle_condition.list.individual_parameter');
      }
    }
    if (
      currentRoute.id === 'driving_environment' &&
      location.pathname.includes('no-entry-zone-setting')
    ) {
      return t('driving_environment.no_entry_zone_setting.title');
    }
    return t(`page.${currentRoute.id}`);
  }, [currentRoute, t, location]);

  const handleClickClearAllNotification = useCallback(() => {
    setHasAlert(false);
    closeSnackbar();
  }, [closeSnackbar, setHasAlert]);

  const handleClickDialogButton = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <Helmet title={title ?? undefined} />
      <AppBar position="sticky" elevation={0}>
        <MaintenanceInfo />
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item sx={{ display: { xs: 'block', xl: 'none' } }}>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={onDrawerToggle}
                    data-testid="menu-icon"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    color="textPrimary"
                    display="inline"
                    data-testid="header-title"
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <SelectedEnvironment data-testid="prjEnvName">
                    {project?.display_name} / {environment?.environment_name}
                  </SelectedEnvironment>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{
                      mr: 2,
                      ml: 4,
                    }}
                    onClick={handleClickDialogButton}
                    data-testid="switchEnv-btn"
                  >
                    {t('header.switch_environment')}
                  </Button>
                </Grid>
                <Grid item>
                  <HeaderMapVersionAlert />
                </Grid>
                <Grid item>
                  <HeaderVehicleAlertList />
                </Grid>
                <Grid item>
                  <Tooltip arrow title={t('notification.clear_all')}>
                    <IconButton
                      color="inherit"
                      data-testid="alert-clear-icon"
                      onClick={handleClickClearAllNotification}
                    >
                      <ClearAll />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <HeaderUserMenu />
                </Grid>
                <Grid item>
                  <NavLink
                    to={`/${project?.id}/${environment?.environment_id}/settings/`}
                  >
                    <IconButton
                      sx={{ color: colors.grey[500] }}
                      data-testid="setting-icon"
                    >
                      <Settings />
                    </IconButton>
                  </NavLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <ProjectEnvironmentDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        project={project!}
        environment={environment!}
      />
    </>
  );
};

export default React.memo(Header);

const SelectedEnvironment = styled(Typography)`
  color: ${colors.grey[700]};
  max-width: 900px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
