import { useVehicleAPI, useFMSWebSocket } from '@api/fms';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMount } from 'react-use';
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';
import store from 'store2';
import { storedVehicleIdAtom, vehiclesAtom } from './states';
import type {
  Vehicle,
  VehicleIndividualParameter,
  VehicleMetadata,
} from './types';

export const useSelectedVehicleId = () => {
  const { storedVehicleId, setStoredVehicleId } = useStoredVehicleId();
  const params = useParams<{ vehicle_id?: string }>();

  const selectedVehicleId = useMemo(() => {
    return params.vehicle_id ?? storedVehicleId ?? undefined;
  }, [params.vehicle_id, storedVehicleId]);

  useMount(() => {
    if (params.vehicle_id) {
      setStoredVehicleId(params.vehicle_id);
    }
  });

  return {
    selectedVehicleId,
  };
};

export const useStoredVehicleId = () => {
  const [storedVehicleId, setStoredVehicleId] =
    useRecoilState(storedVehicleIdAtom);

  useEffect(() => {
    if (storedVehicleId) {
      store.local.set('vehicle_id', storedVehicleId);
      return;
    }
    store.local.remove('vehicle_id');
  }, [storedVehicleId]);

  return {
    storedVehicleId,
    setStoredVehicleId,
  };
};

export const useVehiclesDataWebSocket = () => {
  const {
    createSocket: createVehiclesTelemetrySocket,
    data: updatedVehiclesTelemetry,
    closeSocket: closeVehiclesTelemetrySocket,
  } = useFMSWebSocket({ channel: 'environmentVehiclesTelemetry' });
  const {
    createSocket: createVehiclesMetadataSocket,
    data: updatedVehiclesMetadata,
    closeSocket: closeVehiclesMetadataSocket,
  } = useFMSWebSocket({ channel: 'environmentVehiclesMetadataUpdates' });
  const setVehicles = useSetRecoilState(vehiclesAtom);
  const { getVehicle } = useVehicleAPI();

  /**
   * 作成された車両を一覧に追加
   */
  const addCreatedVehicle = useRecoilCallback(
    ({ snapshot }) =>
      async (updatedData: VehicleMetadata) => {
        const vehicles = await snapshot.getPromise(vehiclesAtom);
        const findedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === updatedData.vehicle_id,
        );
        // すでに一覧に作成済み車両がある場合は中断
        if (findedVehicle) return;
        // ない場合は車両情報を取得
        const res = await getVehicle(updatedData.vehicle_id);
        if (!res) return;
        setVehicles((prevState) =>
          [...prevState, res].sort((a: Vehicle, b: Vehicle) =>
            a.vehicle_name < b.vehicle_name ? -1 : 1,
          ),
        );
      },
    [getVehicle, setVehicles],
  );

  /**
   * 削除された車両を一覧から取り除く
   */
  const removeDeletedVehicle = useRecoilCallback(
    ({ snapshot }) =>
      async (updatedData: VehicleMetadata) => {
        const vehicles = await snapshot.getPromise(vehiclesAtom);
        const findedVehicle = vehicles.find(
          (vehicle) => vehicle.vehicle_id === updatedData.vehicle_id,
        );
        // すでに一覧に削除済み車両がない場合は中断
        if (!findedVehicle) return;
        // ある場合は一覧から削除
        setVehicles((prevState) =>
          prevState.filter(
            (vehicle) => vehicle.vehicle_id !== updatedData.vehicle_id,
          ),
        );
      },
    [setVehicles],
  );

  /**
   * 更新された VehicleMetaData を既存のデータにマージして返す
   */
  const getMergedVehicleMetadata = useCallback(
    (vehicle: Vehicle, updatedData: VehicleMetadata): Vehicle => {
      if (!vehicle.calibration_parameter) {
        // 更新情報に calibration_parameter が無い場合
        return {
          ...vehicle,
          ...updatedData,
        };
      }
      // 更新情報に calibration_parameter がある場合
      const newParameterCadidateStatuses =
        updatedData.calibration_parameter?.new_parameter_candidate_statuses;

      const getMergedCalibParam = () => {
        const calibParam: VehicleIndividualParameter = {
          ...vehicle.calibration_parameter,
          ...updatedData.calibration_parameter,
        };
        if (!newParameterCadidateStatuses) {
          return calibParam;
        }
        if (!newParameterCadidateStatuses.accel_brake_map) {
          // 更新情報に calibration_parameter.new_parameter_candidate_statuses がある場合
          return {
            ...calibParam,
            new_parameter_candidate_statuses: {
              ...vehicle.calibration_parameter.new_parameter_candidate_statuses,
              ...newParameterCadidateStatuses,
            },
          };
        }
        // 更新情報に calibration_parameter.new_parameter_candidate_statuses.accel_brake_map がある場合
        return {
          ...calibParam,
          new_parameter_candidate_statuses: {
            ...calibParam.new_parameter_candidate_statuses,
            accel_brake_map: {
              ...vehicle.calibration_parameter.new_parameter_candidate_statuses
                .accel_brake_map,
              ...newParameterCadidateStatuses.accel_brake_map,
            },
          },
        };
      };
      return {
        ...vehicle,
        ...updatedData,
        calibration_parameter: getMergedCalibParam(),
      };
    },
    [],
  );

  /**
   * Telemetry 更新時の処理
   */
  useEffect(() => {
    if (!updatedVehiclesTelemetry) return;
    setVehicles((prevState) => {
      return prevState.map((vehicle) =>
        vehicle.vehicle_id === updatedVehiclesTelemetry.vehicle_id!
          ? {
              ...vehicle,
              telemetry: {
                ...vehicle.telemetry,
                ...updatedVehiclesTelemetry,
              },
            }
          : vehicle,
      );
    });
  }, [updatedVehiclesTelemetry, setVehicles]);

  /**
   * Metadata 更新時の処理
   */
  useEffect(() => {
    if (!updatedVehiclesMetadata) return;
    const updatesType = updatedVehiclesMetadata.updates_type;
    const updatedData = updatedVehiclesMetadata.vehicle_data;
    if (updatesType === 'CREATED') {
      // 作成時
      addCreatedVehicle(updatedData);
      return;
    }
    if (updatesType === 'DELETED') {
      // 削除時
      removeDeletedVehicle(updatedData);
      return;
    }
    // 更新時
    setVehicles((prevState) =>
      prevState.map((vehicle) =>
        vehicle.vehicle_id === updatedData.vehicle_id
          ? getMergedVehicleMetadata(vehicle, updatedData)
          : vehicle,
      ),
    );
  }, [
    updatedVehiclesMetadata,
    getMergedVehicleMetadata,
    setVehicles,
    addCreatedVehicle,
    removeDeletedVehicle,
  ]);

  return {
    createVehiclesTelemetrySocket,
    closeVehiclesTelemetrySocket,
    createVehiclesMetadataSocket,
    closeVehiclesMetadataSocket,
  };
};
