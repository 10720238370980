import type { APIState, Result } from '@api';
import { Failure, Success, useErrorMessage, useFMSAPI } from '@api';
import { projectAtom, useEnableGetVehicles } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { useNotification } from '@data/notification';
import { useTranslation } from 'react-i18next';
import { overrideTelemetryEmergencyStatus } from '@data/fms/vehicle/utils';
import type { Vehicle } from '@data/fms/vehicle/types';

/**
 * 走行環境内の車両一覧取得API
 */
export const useVehiclesAPI = (): {
  state: APIState;
  getVehicles: () => Promise<Vehicle[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const fmsAPI = useFMSAPI();
  const enableGetVehicles = useEnableGetVehicles();
  const { notifyError } = useNotification();
  const { t } = useTranslation();

  const getVehiclesAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<Vehicle[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles`,
        );
        if (isNullOrUndefined(res?.data)) return new Success([]);
        // 昇順ソート
        res?.data?.sort((a: Vehicle, b: Vehicle) => {
          if (a.vehicle_name < b.vehicle_name) return -1;
          return 1;
        });
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getVehicles = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        if (!enableGetVehicles) return [];
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (isNullOrUndefined(project) || isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.get_vehicles', { status: 'failed' }),
          });
          setState('hasError');
          return [];
        }
        setState('loading');
        const res = await getVehiclesAPI(
          project!.id,
          environment!.environment_id,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
    [getVehiclesAPI, enableGetVehicles, notifyError, t],
  );

  return {
    state,
    getVehicles,
  };
};

/**
 * 指定車両の情報取得API
 */
export const useVehicleAPI = (): {
  state: APIState;
  getVehicle: (vehicleId: string) => Promise<Vehicle | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const enableGetVehicles = useEnableGetVehicles();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getVehicleAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
    ): Promise<Result<Vehicle | null, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getVehicle = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string) => {
        setState('loading');
        if (!enableGetVehicles) {
          setState('hasError');
          return null;
        }
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (isNullOrUndefined(project) || isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.get_vehicle', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        const res = await getVehicleAPI(
          project!.id,
          environment!.environment_id,
          vehicleId,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_vehicle', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        if (res?.value.telemetry.status === 'shutdown') {
          overrideTelemetryEmergencyStatus(res.value.telemetry);
        }
        setState('hasValue');
        return res.value;
      },
    [notifyError, getVehicleAPI, enableGetVehicles, t, getErrorMessage],
  );

  return {
    state,
    getVehicle,
  };
};
