import { useVehiclesDrivenAPI } from '@api/datahub/getVehiclesDriven';
import { useCallback } from 'react';
import { atom, selectorFamily, useRecoilState } from 'recoil';
import type { VehicleDriven } from './types';

export const vehiclesDrivenAtom = atom<VehicleDriven[]>({
  key: 'VehiclesDrivenAtom',
  default: [],
});

export const findVehicleDrivenSelectorFamily = selectorFamily<
  VehicleDriven | undefined,
  string
>({
  key: 'findVehicleDrivenSelectorFamily',
  get:
    (vehicleId) =>
    ({ get }) => {
      const vehicles = get(vehiclesDrivenAtom);
      return vehicles.find((vehicle) => vehicle.vehicle_id === vehicleId);
    },
});

export const useVehiclesDriven = () => {
  const { getVehiclesDriven } = useVehiclesDrivenAPI();
  const [vehiclesDriven, setVehiclesDriven] =
    useRecoilState(vehiclesDrivenAtom);

  const loadVehiclesDriven = useCallback(async () => {
    const res = await getVehiclesDriven();
    setVehiclesDriven(res);
  }, [getVehiclesDriven, setVehiclesDriven]);

  return {
    loadVehiclesDriven,
    vehiclesDriven,
  };
};
