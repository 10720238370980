import { isAuthErrorAtom } from '@data/auth';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { ReloadRequiredDialog } from '@components/common/molecules';

const AuthErrorDialog: React.FC = () => {
  const isAuthError = useRecoilValue(isAuthErrorAtom);

  return (
    <ReloadRequiredDialog
      isOpen={isAuthError}
      title={'dialog.auth_error.title'}
      message={'dialog.auth_error.message'}
    />
  );
};

export default AuthErrorDialog;
