import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  styled,
  TextField,
  Box,
} from '@mui/material';
import {
  EnvironmentList,
  ProjectList,
  ProjectEnvironmentList,
} from '../ProjectEnvironmentList';
import { grey } from '@mui/material/colors';
import type { SelectedTempState } from '@components/common/organisms';
import type { Project } from '@data/auth';
import { hasCreateEnvironmentScopeAtom, projectsAtom } from '@data/auth';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import type { Environment } from '@data/fms/environment/types';
import { environmentsAtom } from '@data/fms/environment/states';

type Props = {
  tempSelect: SelectedTempState;
  isInitialSelected: boolean;
  onCreateEnvironment: () => void;
  onSelectProject: (value: Project) => void;
  onSelectEnvironment: (value: Environment) => void;
  onOk: () => void;
  onClose: () => void;
};

const ProjectEnvironmentDialogList: React.FC<Props> = ({
  tempSelect,
  isInitialSelected,
  onCreateEnvironment,
  onSelectProject,
  onSelectEnvironment,
  onOk,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const projects = useRecoilValue(projectsAtom);
  const environments = useRecoilValue(environmentsAtom);
  const [searchProjectValue, setSearchProjectValue] = useState('');
  const [searchEnvironmentValue, setSearchEnvironmentValue] = useState('');

  const hasCreateEnvironmentScope = useRecoilValue(
    hasCreateEnvironmentScopeAtom,
  );

  const okDisabled = useMemo(() => {
    if (!tempSelect.project || !tempSelect.environment) return true;
    return tempSelect.project.id !== tempSelect.environment.project_id;
  }, [tempSelect]);

  const handleChangeSearchProjects = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchProjectValue(e.target.value.toLowerCase());
    },
    [],
  );

  const searchedProjects = useMemo(() => {
    return projects.filter((project) =>
      project.display_name.toLowerCase().includes(searchProjectValue),
    );
  }, [projects, searchProjectValue]);

  const handleChangeSearchEnvironments = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchEnvironmentValue(e.target.value.toLowerCase());
    },
    [],
  );

  const searchedEnvironments = useMemo(() => {
    return environments.filter((environment) =>
      environment.environment_name
        .toLowerCase()
        .includes(searchEnvironmentValue),
    );
  }, [environments, searchEnvironmentValue]);

  return (
    <>
      <DialogTitle data-testid="prjEnvSelect-title">
        {t('project_environment_dialog.title')}
      </DialogTitle>
      <DialogContent>
        <ContentGrid container justifyContent="space-between">
          <ListWrapper item>
            <ListHeader>
              <ListTitle data-testid="prjEnvSelect-prj">
                {t('common.fms.project')} ({projects.length})
              </ListTitle>
            </ListHeader>
            <Box my={4}>
              <TextField
                fullWidth
                size="small"
                InputProps={{ startAdornment: <Search color="disabled" /> }}
                placeholder={t('common.action.search')}
                onChange={handleChangeSearchProjects}
              />
            </Box>
            <ProjectEnvironmentList data-testid="prjList">
              <ProjectList
                selected={tempSelect.project}
                onSelect={onSelectProject}
                displayProjects={searchedProjects}
              />
            </ProjectEnvironmentList>
          </ListWrapper>
          <ListWrapper item>
            <ListHeader
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <ListTitle data-testid="prjEnvSelect-env">
                  {t('page.driving_environment')} ({environments.length})
                </ListTitle>
              </Grid>
              {hasCreateEnvironmentScope && (
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={onCreateEnvironment}
                    data-testid="prjEnvSelect-createEnv-btn"
                  >
                    {t('project_environment_dialog.create_environment')}
                  </Button>
                </Grid>
              )}
            </ListHeader>
            <Box my={4}>
              <TextField
                fullWidth
                size="small"
                InputProps={{ startAdornment: <Search color="disabled" /> }}
                placeholder={t('common.action.search')}
                onChange={handleChangeSearchEnvironments}
              />
            </Box>
            <ProjectEnvironmentList data-testid="envList">
              <EnvironmentList
                selected={tempSelect.environment}
                project={tempSelect.project}
                onSelect={onSelectEnvironment}
                displayEnvironments={searchedEnvironments}
              />
            </ProjectEnvironmentList>
          </ListWrapper>
        </ContentGrid>
      </DialogContent>
      <DialogActions>
        {!isInitialSelected && (
          <Button
            onClick={onClose}
            color="primary"
            data-testid="prjEnvSelect-cancel-btn"
          >
            {t('common.action.cancel')}
          </Button>
        )}
        <Button
          onClick={onOk}
          disabled={okDisabled}
          color="primary"
          data-testid="prjEnvSelect-ok-btn"
        >
          {t('common.action.ok')}
        </Button>
      </DialogActions>
    </>
  );
};

export default React.memo(ProjectEnvironmentDialogList);

const ContentGrid = styled(Grid)`
  & > div:first-of-type {
    margin-right: 24px;
  }

  & > div:only-of-type {
    margin-right: 0;
  }
`;

const ListWrapper = styled(Grid)`
  display: block;
`;

const ListHeader = styled(Grid)`
  height: 30px;
`;

const ListTitle = styled(Typography)`
  font-size: 16px;
  color: ${grey[600]};
`;
