import { useNoEntryZoneSettingAPI } from '@api/fms/noEntryZoneSettings';
import { useLaneletIdCenterLinePointsAPI } from '@api/fms';
import {
  FEATURE_SCOPES,
  SCOPES,
  useHasFeatureScope,
  useHasScope,
} from '@data/auth';
import { colors } from '@mui/material';
import { useMount, useUnmount } from 'react-use';
import { atom, selector, useSetRecoilState } from 'recoil';
import type {
  NoEntryZone,
  NoEntryZoneCenterLinePoints,
  NoEntryZoneSetting,
  NoEntryZoneSettingSummary,
} from './type';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { DateTime } from 'luxon';
import { en } from '@data/i18n/locales';
import { mapMenuAtom } from '@data/ui/states';
import {
  appliedNoEntryZoneSettingAtom,
  currentAreaMapVersionSelector,
} from '../areamap/states';
import type { TimetableDayOfTheWeek } from '../schedule/types';

export const NO_ENTRY_ZONE_MAX_LIMIT = 20;

export const noEntryZoneSettingsAtom = atom<NoEntryZoneSettingSummary[]>({
  key: 'noEntryZoneSettingsAtom',
  default: [],
});

export const addableNoEntryZoneSettingSelector = selector({
  key: 'addableNoEntryZoneSettingSelector',
  get: ({ get }) => {
    const currentAreaMapVersion = get(currentAreaMapVersionSelector);
    const settings = get(noEntryZoneSettingsAtom);
    return currentAreaMapVersion?.current_applied_no_entry_zone_setting_id
      ? settings.length < NO_ENTRY_ZONE_MAX_LIMIT + 1
      : settings.length < NO_ENTRY_ZONE_MAX_LIMIT;
  },
});

export const selectedNoEntryZoneSettingAtom = atom<NoEntryZoneSetting | null>({
  key: 'selectedNoEntryZoneSettingAtom',
  default: null,
});

export const isOtherAreaMapVersionAppliedSelectedNoEntryZoneSettingSelector =
  selector({
    key: 'isOtherAreaMapVersionAppliedSelectedNoEntryZoneSettingSelector',
    get: ({ get }) => {
      const setting = get(selectedNoEntryZoneSettingAtom);
      return (
        !isNullOrUndefined(setting) &&
        !isNullOrUndefined(setting.applied_area_map_version_ids) &&
        setting.applied_area_map_version_ids.length > 0
      );
    },
  });

export const selectedNoEntryZoneSettingCenterLinePointsAtom = atom<
  NoEntryZoneCenterLinePoints[]
>({
  key: 'selectedNoEntryZoneSettingCenterLinePointsAtom',
  default: [],
});

export const showNoEntryZoneCenterLinePointsIdAtom = atom<string | null>({
  key: 'showNoEntryZoneCenterLinePointsIdAtom',
  default: null,
});

export const selectedNoEntryZoneAtom = atom<NoEntryZone | null>({
  key: 'selectedNoEntryZoneAtom',
  default: null,
});

export const visibilityLaneIdAtom = atom<number | null>({
  key: 'visibilityLaneIdAtom',
  default: null,
});

export const isNotfoundLaneletAtom = atom({
  key: 'isNotfoundLaneletAtom',
  default: false,
});

export const polylineColor = {
  selected: colors.red[500],
  visibility: colors.orange[600],
  added: colors.pink[300],
} as const;

export const isPeriod = (noEntryZone: NoEntryZone) => {
  return !!noEntryZone.start_hm;
};

/**
 * 走行環境に設定されている通行禁止区域設定とレーン情報を取得
 */
export const useLoadAppliedNoEntryZoneSettingWithLanelets = () => {
  const getHasScope = useHasScope();
  const getHasFeatureScope = useHasFeatureScope();
  const { getAppliedNoEntryZoneSetting } = useNoEntryZoneSettingAPI();
  const { getNoEntryZoneSettingLanelets } = useLaneletIdCenterLinePointsAPI();
  const setAppliedNoEntryZoneSetting = useSetRecoilState(
    appliedNoEntryZoneSettingAtom,
  );
  const setMapMenu = useSetRecoilState(mapMenuAtom);

  useMount(async () => {
    if (
      !getHasFeatureScope(FEATURE_SCOPES.NoEntryZoneManagement) ||
      !getHasScope(SCOPES.DescribeNoEntryZoneSetting)
    )
      return;
    const res = await getAppliedNoEntryZoneSetting();
    if (res) {
      const laneletsRes = await getNoEntryZoneSettingLanelets(res);
      setAppliedNoEntryZoneSetting(laneletsRes);
      setMapMenu((prevState) => ({
        ...prevState,
        noEntryZones: Object.fromEntries(
          res.no_entry_zones.map((zone) => [zone.no_entry_zone_id, true]),
        ),
      }));
    }
  });

  useUnmount(() => {
    setAppliedNoEntryZoneSetting(null);
  });
};

/**
 *
 */
export const isCurrentlyAppliedNoEntryZone = (zone: NoEntryZone) => {
  if (isNullOrUndefined(zone)) return false;
  const now = DateTime.now();
  if (isPeriod(zone)) {
    // 定期指定
    const dayIndex = now.weekday;
    const daysOfWeek = Object.keys(en.common.day_of_week.original).map(
      (key) => key,
    );
    const day = daysOfWeek[dayIndex - 1];
    if (!zone.days_of_the_week!.includes(day as TimetableDayOfTheWeek)) {
      // 通行禁止区域の曜日に今日の曜日が含まれていない場合
      return false;
    }
    const startHmSplit = zone.start_hm!.split(':');
    const startHm = now.set({
      hour: Number(startHmSplit[0]),
      minute: Number(startHmSplit[1]),
    });
    const endHmSplit = zone.end_hm!.split(':');
    const endHm = now.set({
      hour: Number(endHmSplit[0]),
      minute: Number(endHmSplit[1]),
    });
    return (
      startHm.startOf('minute') <= now.startOf('minute') &&
      now.endOf('minute') <= endHm.endOf('minute')
    );
  }
  // 日時指定
  const startTime = DateTime.fromISO(zone.start_time!);
  const endTime = DateTime.fromISO(zone.end_time!);
  return (
    startTime.startOf('minute') <= now.startOf('minute') &&
    now.endOf('minute') <= endTime.endOf('minute')
  );
};
