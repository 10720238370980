import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

/**
 * 指定車両緊急停止リクエストAPI
 */
export const useVehicleEmergencyStopAPI = (): {
  state: APIState;
  postVehicleEmergencyStop: (vehicleId: string) => Promise<boolean>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError, notifySuccess } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const postVehicleEmergencyStopAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
    ): Promise<Result<boolean, AxiosResponse>> => {
      try {
        await fmsAPI.post(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/emergency_stop`,
        );
        return new Success(true);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const postVehicleEmergencyStop = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string) => {
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (isNullOrUndefined(project) || isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.post_vehicle_emergency_stop', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return false;
        }
        setState('loading');
        const res = await postVehicleEmergencyStopAPI(
          project!.id,
          environment!.environment_id,
          vehicleId,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.post_vehicle_emergency_stop', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return false;
        }
        notifySuccess({
          message: t('api.fms.post_vehicle_emergency_stop', {
            status: 'successfully',
          }),
        });
        setState('hasValue');
        return res.value;
      },
    [
      notifyError,
      notifySuccess,
      postVehicleEmergencyStopAPI,
      t,
      getErrorMessage,
    ],
  );

  return {
    state,
    postVehicleEmergencyStop,
  };
};

/**
 * 走行環境内の全車両緊急停止リクエストAPI
 */
export const useAllVehicleEmergencyStopAPI = (): {
  state: APIState;
  postAllVehicleEmergencyStop: () => Promise<boolean>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError, notifySuccess } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const postAllVehicleEmergencyStopAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<boolean, AxiosResponse>> => {
      try {
        await fmsAPI.post(
          `/${projectId}/environments/${environmentId}/vehicles/emergency_stop`,
        );
        return new Success(true);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const postAllVehicleEmergencyStop = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (isNullOrUndefined(project) || isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.post_vehicle_emergency_stop', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return false;
        }
        setState('loading');
        const res = await postAllVehicleEmergencyStopAPI(
          project!.id,
          environment!.environment_id,
        );
        if (isNullOrUndefined(res.value) || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.post_vehicle_emergency_stop', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return false;
        }
        notifySuccess({
          message: t('api.fms.post_vehicle_emergency_stop', {
            status: 'successfully',
          }),
        });
        setState('hasValue');
        return res.value;
      },
    [
      notifyError,
      notifySuccess,
      postAllVehicleEmergencyStopAPI,
      t,
      getErrorMessage,
    ],
  );

  return {
    state,
    postAllVehicleEmergencyStop,
  };
};
