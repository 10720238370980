import { atom, selector } from 'recoil';
import { environmentAtom } from '../environment/states';
import type { NoEntryZoneSettingWithLanelets } from '../noEntryZone/type';
import type { EnvironmentAreaMapVersion } from './types';

export const areaMapVersionsAtom = atom<EnvironmentAreaMapVersion[]>({
  key: 'areaMapVersions',
  default: [],
});

/**
 * 現在のエリアマップバージョンを返す
 * @return EnvironmentAreaMapVersion[]
 */
export const currentAreaMapVersionSelector = selector<
  EnvironmentAreaMapVersion | undefined
>({
  key: 'currentAreaMapVersionSelector',
  get: ({ get }) => {
    const environment = get(environmentAtom);
    const areaMapVersions = get(areaMapVersionsAtom);
    return areaMapVersions.find(
      (areaMapVersion) =>
        environment?.area_map_version_id === areaMapVersion.version_id,
    );
  },
});

/**
 * 現在のエリアマップバージョンがレーン情報が持っているかどうかを返す
 * @return Boolean
 */
export const hasCurrentAreaMapVersionLaneletsSelector = selector({
  key: 'currentAreaMaversionHasLaneletsSelector',
  get: ({ get }) => {
    const currentAreaMapVersion = get(currentAreaMapVersionSelector);
    return (
      currentAreaMapVersion?.lanelets_center_line_points_status === 'created'
    );
  },
});

/**
 * 現在のエリアマップバージョンに反映されている通行禁止区域設定
 */
export const appliedNoEntryZoneSettingAtom =
  atom<NoEntryZoneSettingWithLanelets | null>({
    key: 'appliedNoEntryZoneSettingAtom',
    default: null,
  });
