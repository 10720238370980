import { useCallback, useState } from 'react';
import type { Result } from '@api';
import { Failure, Success, useErrorMessage, useDatahubAPI } from '@api';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import { useRecoilCallback } from 'recoil';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useTranslation } from 'react-i18next';
import type { VehicleDriven } from '@data/datahub/vehicleDriven/types';

type APIResponse = {
  vehicles: VehicleDriven[];
  page_size: number;
  next_page_token?: string;
};

/**
 * データ基盤の車両一覧取得API
 */
export const useVehiclesDrivenAPI = (): {
  isFirstRequestLoaded: boolean;
  loading: boolean;
  getVehiclesDriven: () => Promise<VehicleDriven[]>;
} => {
  const [loading, setLoading] = useState(false);
  const [isFirstRequestLoaded, setIsFirstRequestLoaded] = useState(false);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const { datahubAPI } = useDatahubAPI();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      params: URLSearchParams,
    ): Promise<Result<APIResponse, AxiosResponse>> => {
      try {
        const res = await datahubAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles_driven?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [datahubAPI],
  );

  const getVehiclesDriven = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        setLoading(true);
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_vehicles_driven', { status: 'failed' }),
          });
          setLoading(false);
          return [];
        }

        const params = new URLSearchParams();
        let count = 0;
        let datas: VehicleDriven[] = [];

        const req = async () =>
          await request(project.id, environment.environment_id, params);

        const splitRequest = async (): Promise<'error' | 'success'> => {
          const res = await req();
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            if (count === 0) {
              // 初回のリクエストに失敗した場合
              notifyError({
                message: `${t('api.datahub.get_vehicles_driven', {
                  status: 'failed',
                })}: ${getErrorMessage(res)}`,
              });
              return 'error';
            } else {
              // 初回以降の途中のリクエストに失敗した場合通知だけ出す
              notifyError({
                message: `${t('api.datahub.get_vehicles_driven', {
                  status: 'failed',
                })}: ${getErrorMessage(res)}`,
              });
            }
          }
          // 取得成功時
          if (!!res && res.isSuccess()) {
            if (count === 0) {
              setIsFirstRequestLoaded(true);
            }
            count += 1;
            // 配列を結合
            datas = datas.concat(res.value.vehicles);
            if (res.value.next_page_token) {
              // レスポンスに next_page がある場合は次のリクエストを行う
              if (params) {
                params.set('page_token', res.value.next_page_token);
              }
              await splitRequest();
            }
          }
          // レスポンスに nextPage がない場合は終了
          return 'success';
        };

        const splitRequestRes = await splitRequest();

        if (splitRequestRes === 'error') {
          setLoading(false);
          return [];
        }

        datas.sort((a: VehicleDriven, b: VehicleDriven) => {
          if (a.name < b.name) return -1;
          return 1;
        });

        setLoading(false);
        return datas;
      },
    [notifyError, request, t, getErrorMessage],
  );

  return {
    isFirstRequestLoaded,
    loading,
    getVehiclesDriven,
  };
};
