import React, { useState, useMemo } from 'react';
import { Paper as MuiPaper, styled, Box } from '@mui/material';
import { spacing } from '@mui/system';
import { Header, Sidebar } from '@components/common/organisms';
import { useRecoilValue } from 'recoil';
import { Call } from '@components/common/molecules';
import { FeatureToggle, ToggleOn } from '@components/common/atoms';
import { FEATURE_SCOPES, SCOPES } from '@data/auth';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { headerHeightSelector } from '@data/maintenance';
import {
  connectedVehiclesSelector,
  remoteMonitorConfigAtom,
} from '@data/fms/vehicle/states';

export const drawerWidth = 260;

const Root = styled(Box)`
  display: flex;
  min-height: 100vh;
`;

const AppContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.body.background};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const connectedVehicles = useRecoilValue(connectedVehiclesSelector(false));
  const remoteMonitorConfig = useRecoilValue(remoteMonitorConfigAtom);
  const headerHeight = useRecoilValue(headerHeightSelector);

  /**
   * 遠隔監視画面を開いている車両は除外
   */
  const excludeCallingVehicles = useMemo(() => {
    if (
      isNullOrUndefined(remoteMonitorConfig) ||
      isNullOrUndefined(remoteMonitorConfig.vehicle)
    ) {
      return connectedVehicles;
    }
    return connectedVehicles.filter(
      (vehicle) =>
        vehicle.vehicle_id !== remoteMonitorConfig.vehicle!.vehicle_id,
    );
  }, [connectedVehicles, remoteMonitorConfig]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDrawerClose = () => {
    setMobileOpen(false);
  };

  // 通話コンポーネント作成
  const incomingCall = useMemo(
    () =>
      excludeCallingVehicles.map((vehicle) => (
        <Call
          vehicleName={vehicle.vehicle_name}
          vehicleId={vehicle.vehicle_id}
          key={vehicle.vehicle_id}
        />
      )),
    [excludeCallingVehicles],
  );

  return (
    <Root>
      <Sidebar
        open={mobileOpen}
        onClose={handleDrawerToggle}
        onSidebarLinkClick={handleDrawerClose}
        variant="temporary"
        sx={{
          display: { xs: 'block', xl: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      />
      <Sidebar
        variant="permanent"
        sx={{
          display: { xs: 'none', xl: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
          },
        }}
      />
      <AppContent
        sx={{
          width: { xs: '100%', xl: `calc(100% - ${drawerWidth}px)` },
          marginLeft: { xs: 0, xl: `${drawerWidth}px` },
        }}
      >
        <Header onDrawerToggle={handleDrawerToggle} />
        <MainContent
          sx={{
            height: `calc(100vh - ${headerHeight}px)`,
          }}
        >
          {children}
        </MainContent>
      </AppContent>
      <FeatureToggle type="featureScope" scope={FEATURE_SCOPES.VoiceCall}>
        <ToggleOn>
          <FeatureToggle
            scope={[SCOPES.DescribeMediaStatus, SCOPES.ReceiveMediaStream]}
          >
            <ToggleOn>{incomingCall}</ToggleOn>
          </FeatureToggle>
        </ToggleOn>
      </FeatureToggle>
    </Root>
  );
};

export default Layout;
