import type { APIState, Result } from '@api';
import { Success, Failure, useErrorMessage, useDatahubAPI } from '@api';
import { projectAtom } from '@data/auth';
import { weatherLocationAtom } from '@data/datahub/weather';
import type { WeatherForecast } from '@data/datahub/weather/types';
import { environmentAtom } from '@data/fms/environment/states';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

export const useWeatherForecastAPI = (): {
  state: APIState;
  getWeatherForecast: (locationId?: string) => Promise<WeatherForecast[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const { t } = useTranslation();
  const getErrorMessage = useErrorMessage();
  const { datahubAPI } = useDatahubAPI();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      locationId: string,
    ): Promise<Result<WeatherForecast[], AxiosResponse>> => {
      try {
        const res = await datahubAPI.get(
          `/${projectId}/environments/${environmentId}/weather_locations/${locationId}/forecast`,
        );
        return new Success(res?.data?.forecasts);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [datahubAPI],
  );

  const getWeatherForecast = useRecoilCallback(
    ({ snapshot }) =>
      async (locationId: string | undefined = undefined) => {
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        let requestLocationId = locationId;
        if (!locationId) {
          const location = await snapshot.getPromise(weatherLocationAtom);
          if (location) {
            requestLocationId = location.location_id;
          }
        }
        if (!project || !environment || !requestLocationId) {
          notifyError({
            message: t('api.datahub.get_weather_forecasts', {
              status: 'failed',
            }),
          });
          setState('hasError');
          return [];
        }
        setState('loading');
        const res = await request(
          project.id,
          environment.environment_id,
          requestLocationId,
        );
        if (!res.value || res.isFailure()) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          if ((res as any).value?.status !== 404) {
            notifyError({
              message: `${t('api.datahub.get_weather_forecasts', {
                status: 'failed',
              })}: ${getErrorMessage(res)}`,
            });
          }
          setState('hasError');
          return [];
        }
        setState('hasValue');
        return res.value;
      },
    [notifyError, t, request, getErrorMessage],
  );

  return {
    state,
    getWeatherForecast,
  };
};
