import { useMaintenanceEvents } from '@api/maintenance';
import {
  displayMaintenanceEventSelector,
  DISPLAY_SERVICE_NAMES,
  maintenanceEventsAtom,
} from '@data/maintenance';
import {
  Box,
  Button,
  Chip,
  colors,
  Grid,
  Popover,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMount } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { Info } from 'react-feather';

const UPDATE_MINUTES = 5;

const MaintenanceInfo: React.FC = () => {
  const timer = useRef<number>();
  const setMaintenanceEvents = useSetRecoilState(maintenanceEventsAtom);
  const { getMaintenanceEvents } = useMaintenanceEvents();
  const { t, i18n } = useTranslation();
  const displayMaintenanceEvent = useRecoilValue(
    displayMaintenanceEventSelector,
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const updateMaintenanceInfo = useCallback(async () => {
    const res = await getMaintenanceEvents();
    setMaintenanceEvents(res);
  }, [getMaintenanceEvents, setMaintenanceEvents]);

  const summary = useMemo(() => {
    if (!displayMaintenanceEvent) return null;
    if (i18n.language === 'ja') return displayMaintenanceEvent.summary.ja;
    if (i18n.language === 'en-US') return displayMaintenanceEvent.summary.en;
  }, [displayMaintenanceEvent, i18n.language]);

  const endTime = useMemo(() => {
    if (!displayMaintenanceEvent || !displayMaintenanceEvent.end_time)
      return null;
    const startTimeLuxon = DateTime.fromISO(displayMaintenanceEvent.start_time);
    const endTimeLuxon = DateTime.fromISO(displayMaintenanceEvent.end_time);
    if (
      startTimeLuxon.startOf('day').toUnixInteger() ===
      endTimeLuxon.startOf('day').toUnixInteger()
    ) {
      // 開始時間と終了時間が同日の場合
      return endTimeLuxon.toFormat('HH:mm');
    }
    return endTimeLuxon.toFormat('M/dd HH:mm');
  }, [displayMaintenanceEvent]);

  const color = useMemo(() => {
    if (displayMaintenanceEvent?.type === 'scheduled') {
      return colors.blue[600];
    }
    if (displayMaintenanceEvent?.type === 'unplanned') {
      const level = displayMaintenanceEvent.incident_level;
      if (level === 'critical' || level === 'high') {
        return colors.red[500];
      }
      if (level === 'moderate' || level === 'low') {
        return colors.orange[700];
      }
      if (level === 'informational') {
        return colors.blueGrey[600];
      }
    }
    return colors.blue[600];
  }, [displayMaintenanceEvent]);

  const handleClickDetail = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    },
    [],
  );

  const handleCloseDetail = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const period = useMemo(() => {
    if (!displayMaintenanceEvent) return null;
    return (
      <>
        {DateTime.fromISO(displayMaintenanceEvent.start_time).toFormat(
          'yyyy/M/dd HH:mm',
        )}
        {endTime && ` - ${endTime}`}
      </>
    );
  }, [displayMaintenanceEvent, endTime]);

  const detailContent = useMemo(() => {
    if (!displayMaintenanceEvent) return null;

    return (
      <Box p={4} width={500}>
        <Grid container justifyContent="space-between" alignItems="flex-end">
          <Grid item>
            <Typography fontWeight={600}>
              {t(`maintenance.type.${displayMaintenanceEvent.type}`)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption" color="gray">
              {t('maintenance.last_updated_time')}:{' '}
              {DateTime.fromISO(
                displayMaintenanceEvent.last_updated_time,
              ).toFormat('yyyy/MM/dd HH:mm:ss')}
            </Typography>
          </Grid>
        </Grid>
        <TableContainer
          sx={{ mt: 3, borderTop: `solid 1px ${colors.grey[300]}` }}
        >
          <Table>
            <TableBody
              sx={{
                td: {
                  padding: 3,
                  fontSize: 13,
                  '&:first-of-type': {
                    width: '25%',
                    fontWeight: 'bold',
                  },
                },
              }}
            >
              <TableRow>
                <TableCell>{t('common.date.time')}</TableCell>
                <TableCell>{period}</TableCell>
              </TableRow>
              {displayMaintenanceEvent.incident_level && (
                <TableRow>
                  <TableCell>{t('maintenance.incident_level.label')}</TableCell>
                  <TableCell>
                    {t(
                      `maintenance.incident_level.${displayMaintenanceEvent.incident_level}`,
                    )}
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>{t('common.status.status')}</TableCell>
                <TableCell>
                  {t(`maintenance.status.${displayMaintenanceEvent.status}`)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('common.general.summary')}</TableCell>
                <TableCell>{summary}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ whiteSpace: 'pre-line' }}>
                  {t('maintenance.target_services')}
                </TableCell>
                <TableCell>
                  <Grid container spacing={2} wrap="wrap">
                    {displayMaintenanceEvent.target_services.map((service) => (
                      <Grid item key={service}>
                        <Chip
                          size="small"
                          color="secondary"
                          label={DISPLAY_SERVICE_NAMES[service]}
                          sx={{
                            fontSize: 12,
                            height: 20,
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  }, [displayMaintenanceEvent, summary, period, t]);

  useMount(() => {
    // タイマー開始
    timer.current = window.setInterval(
      updateMaintenanceInfo,
      1000 * 60 * UPDATE_MINUTES,
    );
    updateMaintenanceInfo();
  });

  useEffect(() => {
    if (displayMaintenanceEvent) {
      // body に data-maintenance 属性追加
      document.body.setAttribute('data-maintenance', 'true');
    } else {
      document.body.removeAttribute('data-maintenance');
    }
  }, [displayMaintenanceEvent]);

  if (!displayMaintenanceEvent) {
    return <></>;
  }

  return (
    <Wrapper baseColor={color}>
      <Grid container spacing={4} alignItems="center">
        <Grid item>
          <DetailButton
            size="small"
            variant="outlined"
            baseColor={color}
            startIcon={<Info size={12} />}
            onClick={handleClickDetail}
            data-testid="maintenanceInfoDetail-btn"
          >
            {t('common.general.detail')}
          </DetailButton>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleCloseDetail}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: -5,
              horizontal: 0,
            }}
          >
            {detailContent}
          </Popover>
        </Grid>
        <Grid item>
          <Typography
            fontWeight={500}
            lineHeight={1}
            data-testid="maintenanceTimeInfo"
          >
            {period}
          </Typography>
        </Grid>
        <Grid item>
          <Typography data-testid="maintenanceInfoMessage">
            {summary}
          </Typography>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default MaintenanceInfo;

type BaseColorProps = {
  baseColor: string;
};

const Wrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== 'baseColor',
})<BaseColorProps>`
  overflow: hidden;
  color: white;
  background-color: ${({ baseColor }) => baseColor};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
  height: 38px;
`;

const DetailButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== 'baseColor',
})<BaseColorProps>`
  color: ${({ baseColor }) => baseColor};
  border: none;
  background-color: white;
  font-size: 11px;
  padding: 2px 0 0;
  margin-bottom: 2px;
  &:hover {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
  }
`;
