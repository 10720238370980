import isNullOrUndefined from '@utils/isNullOrUndefined';
import isEqual from 'lodash.isequal';
import React, { useEffect, useMemo, useRef } from 'react';
import { useMount, usePrevious } from 'react-use';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useNotification } from '@data/notification';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';
import {
  enableWeatherInfoSelector,
  useWeatherInfo,
  weatherForecastsAtom,
} from '@data/datahub/weather';
import { environmentOptionAtom } from '@data/fms/environment/states';

const UPDATE_MINUTES = 20;

const WeatherNotification: React.FC = () => {
  const { t } = useTranslation();
  const timer = useRef<number>();
  const getWeatherInfo = useWeatherInfo();
  const weatherForecasts = useRecoilValue(weatherForecastsAtom);
  const environmentOption = useRecoilValue(environmentOptionAtom);
  const { notifyError } = useNotification();

  /**
   * 天気予報を取得
   */
  const updateWeather = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const enableWeatherInfo = await snapshot.getPromise(
          enableWeatherInfoSelector,
        );
        // 天気地点情報がない場合は中断
        if (!enableWeatherInfo) return;
        getWeatherInfo();
      },
    [getWeatherInfo],
  );

  /**
   * 通知を出す対象の天気予報
   */
  const targetWeatherForecast = useMemo(() => {
    if (isNullOrUndefined(environmentOption)) return;
    return [...weatherForecasts].splice(
      environmentOption.weather_forecast_after_hours_threshold - 1,
      1,
    )[0];
  }, [environmentOption, weatherForecasts]);

  const prevTargetWeatherForecast = usePrevious(targetWeatherForecast);

  useMount(() => {
    // タイマー開始
    timer.current = window.setInterval(
      updateWeather,
      1000 * 60 * UPDATE_MINUTES,
    );
  });

  useEffect(() => {
    if (
      // 直前の天気予報と現在の天気予報が同じ場合
      isEqual(prevTargetWeatherForecast, targetWeatherForecast) ||
      // 対象の天気予報が参照できない場合
      isNullOrUndefined(targetWeatherForecast) ||
      // 走行環境オプションが参照できない場合
      isNullOrUndefined(environmentOption) ||
      // 天気予報通知がOFFの場合
      !environmentOption.enable_weather_forecast_notification ||
      // 対象の降水量が通知設定の降水量より少ない場合
      targetWeatherForecast.precipitation <
        environmentOption.weather_forecast_precipitation_threshold
    ) {
      return;
    }
    const forecastLuxon = DateTime.fromISO(targetWeatherForecast.forecast_time);
    const currentLuxon = DateTime.now().startOf('hour');
    const diff = forecastLuxon.diff(currentLuxon, 'hours');
    notifyError({
      message: (
        <Box className="weather-notification" data-alert="true">
          <Typography variant="subtitle2">
            {t('notification.weather.precipitation.title')}
          </Typography>
          <Box mt={2}>
            <Typography>
              <Trans
                i18nKey="notification.weather.precipitation.message"
                values={{
                  count: diff.hours,
                  precipitation: targetWeatherForecast.precipitation,
                }}
              />
            </Typography>
          </Box>
        </Box>
      ),
    });
  }, [
    prevTargetWeatherForecast,
    targetWeatherForecast,
    environmentOption,
    notifyError,
    t,
  ]);

  return null;
};

export default React.memo(WeatherNotification);
