import { AuthenticationManager } from '@api/auth';
import useServiceStatus from '@api/status';
import { userAtom } from '@data/auth';
import { executeWhenVisible } from '@tier4/webauto-auth-frontend-client';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

const SignInCallback: React.FC = () => {
  const setUser = useSetRecoilState(userAtom);
  const navigate = useNavigate();
  const { checkServiceStatus } = useServiceStatus();

  useEffect(() => {
    (async () => {
      const serviceStatus = await checkServiceStatus();
      if (!serviceStatus) {
        return;
      }

      try {
        const user = await AuthenticationManager.userManager.signInCallback();
        if (user) setUser(user);
      } catch {
        try {
          const signInRedirect = async () =>
            AuthenticationManager.userManager.signInRedirect();
          await executeWhenVisible(signInRedirect);
        } catch (err) {
          console.log(err);
        }
      }
    })();
  }, [setUser, navigate, checkServiceStatus]);
  return <></>;
};

export default SignInCallback;
