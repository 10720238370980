import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { MediaClient } from '@tier4/webauto-media-client';
import type { RemoteCamera } from '@tier4/webauto-media-client/dist/MediaClient';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useRecoilCallback } from 'recoil';
import { useNotification } from '@data/notification';
import { useTranslation } from 'react-i18next';

export const useRemoteCamera = (): {
  getCameras: (vehicleId: string | undefined) => Promise<RemoteCamera[]>;
} => {
  const { notifyError } = useNotification();
  const { t } = useTranslation();

  const getCameras = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string | undefined) => {
        if (isNullOrUndefined(vehicleId)) return [];
        try {
          const project = await snapshot.getPromise(projectAtom);
          const environment = await snapshot.getPromise(environmentAtom);
          if (isNullOrUndefined(project)) {
            notifyError({
              message: t('common.text.none', { name: t('common.fms.project') }),
            });
            return [];
          }
          if (isNullOrUndefined(environment)) {
            notifyError({
              message: t('common.text.none', {
                name: t('common.fms.driving_environment'),
              }),
            });
            return [];
          }
          const devices = await MediaClient.getRemoteDevices({
            projectId: project!.id,
            vehicleId,
            environmentId: environment!.environment_id,
          });
          return devices.cameras;
        } catch (error) {
          console.log(error);
          return [];
        }
      },
    [notifyError, t],
  );

  return {
    getCameras,
  };
};
