import { atom } from 'recoil';
import type { MapMenu } from './types';

export const mapMenuAtom = atom<MapMenu>({
  key: 'mapMenuAtom',
  default: {
    stopPointNames: {},
    noEntryZones: {},
    laneletsCenterPoints: false,
  },
});
