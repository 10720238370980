import type valueof from '@utils/valueof';
import { useCallback } from 'react';
import { useMount } from 'react-use';
import { useRecoilState } from 'recoil';
import store from 'store2';
import { DEFAULT_SETTINGS, SETTINGS_STORAGE_KEY_NAME } from './constants';
import { settingsAtom } from './states';
import type { AlertSetting, Settings, VehicleSetting } from './types';

export const useSettings = () => {
  const [settings, setSettings] = useRecoilState(settingsAtom);

  const updateSetting = useCallback(
    (key: keyof Settings, value: valueof<Settings>) => {
      const saveSettings = { ...settings, [key]: value };
      store.local.set(SETTINGS_STORAGE_KEY_NAME, saveSettings);
      setSettings(saveSettings);
    },
    [setSettings, settings],
  );

  const updateAlertSetting = useCallback(
    (key: keyof AlertSetting, value: valueof<AlertSetting>) => {
      const saveAlert = { ...settings.alert, [key]: value };
      updateSetting('alert', { ...settings.alert, ...saveAlert });
    },
    [updateSetting, settings.alert],
  );

  const updateVehicleSetting = useCallback(
    (key: keyof VehicleSetting, value: valueof<VehicleSetting>) => {
      const saveVehicle = {
        ...settings.vehicle,
        [key]: value,
      };
      updateSetting('vehicle', { ...settings.vehicle, ...saveVehicle });
    },
    [updateSetting, settings.vehicle],
  );

  useMount(() => {
    if (store.local.get(SETTINGS_STORAGE_KEY_NAME)) return;
    // ローカルストレージに `settings` が無い場合デフォルト値を保存
    store.local.set(SETTINGS_STORAGE_KEY_NAME, DEFAULT_SETTINGS);
  });

  return {
    settings,
    updateAlertSetting,
    updateVehicleSetting,
  };
};
