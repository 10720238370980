import { useCallback, useState } from 'react';
import type { Result } from '@api';
import { Failure, Success, useErrorMessage, useDatahubAPI } from '@api';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import { useRecoilCallback } from 'recoil';
import { projectAtom } from '@data/auth';
import { useTranslation } from 'react-i18next';
import type {
  Diagnostic,
  DiagnosticLevelString,
  DiagnosticValueHistory,
} from '@data/datahub/diagnostics/types';
import { DateTime } from 'luxon';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { environmentAtom } from '@data/fms/environment/states';
import { DIAGNOSTIC_LEVEL_STRING } from '@data/datahub/diagnostics/constants';

type APIResponse = {
  page_size: number;
  next_page_token?: string;
};

type DiagnosticResponse = APIResponse & {
  diagnostics: Diagnostic[];
};

type DiagnosticValueResponse = APIResponse & {
  value_history: DiagnosticValueHistory[];
};

export const useDiagnosticsRequest = () => {
  const { datahubAPI } = useDatahubAPI({ cancelWhenUnmounting: true });

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
      params: URLSearchParams,
    ): Promise<Result<DiagnosticResponse, AxiosResponse>> => {
      try {
        const res = await datahubAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/diagnostics?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return axios.isCancel(error)
          ? new Success({
              diagnostics: [],
              page_size: 0,
            })
          : new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [datahubAPI],
  );

  return request;
};

export const useAsyncDiagnosticsAPI = (): {
  loading: boolean;
  diagnostics: Diagnostic[];
  getAsyncDiagnostics: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<void>;
} => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const [diagnostics, setDiagnostics] = useState<Diagnostic[]>([]);
  const request = useDiagnosticsRequest();

  const getAsyncDiagnostics = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);

        setDiagnostics([]);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_diagnostics', { status: 'failed' }),
          });
          setLoading(false);
          return;
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        const splitRequest = async (): Promise<void> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            notifyError({
              message: `${t('api.datahub.get_diagnostics', {
                status: 'failed',
              })}: ${getErrorMessage(res)}`,
            });
            return;
          }
          // 取得成功時
          if (!!res && res.isSuccess()) {
            // 配列を結合
            setDiagnostics((prevState) =>
              prevState.concat(res.value.diagnostics),
            );
            if (res.value.next_page_token) {
              // レスポンスに next_page がある場合は次のリクエストを行う
              if (params) {
                params.set('page_token', res.value.next_page_token);
              }
              await splitRequest();
            } else {
              setLoading(false);
            }
          }
        };

        await splitRequest();

        setLoading(false);
      },
    [notifyError, request, t, getErrorMessage],
  );

  return {
    loading,
    getAsyncDiagnostics,
    diagnostics,
  };
};

export const useAsyncExcludeLevelDiagnosticsAPI = () => {
  const [loading, setLoading] = useState(false);
  const { diagnostics, getAsyncDiagnostics } = useAsyncDiagnosticsAPI();

  const getAsyncExcludeLevelDiagnostics = useRecoilCallback(
    () =>
      async (
        vehicleId: string,
        excludeLevels: DiagnosticLevelString[],
        params: URLSearchParams,
      ) => {
        setLoading(true);
        const requestLevels = DIAGNOSTIC_LEVEL_STRING.filter(
          (level) => !excludeLevels.includes(level),
        );
        await Promise.all(
          requestLevels.map(async (level) => {
            const requestParams = new URLSearchParams(params);
            requestParams.delete('level');
            requestParams.append('level', level.toString());
            await getAsyncDiagnostics(vehicleId, requestParams);
          }),
        );

        setLoading(false);
      },
    [getAsyncDiagnostics],
  );

  return {
    diagnostics,
    getAsyncExcludeLevelDiagnostics,
    loading,
  };
};

export const useSyncDiagnosticsAPI = (): {
  loading: boolean;
  getSyncDiagnostics: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<Diagnostic[]>;
} => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const request = useDiagnosticsRequest();

  const getSyncDiagnostics = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_diagnostics', { status: 'failed' }),
          });
          setLoading(false);
          return [];
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        let datas: Diagnostic[] = [];

        const splitRequest = async (): Promise<'success' | 'error'> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            notifyError({
              message: `${t('api.datahub.get_diagnostics', {
                status: 'failed',
              })}: ${getErrorMessage(res)}`,
            });
            return 'error';
          }
          // 取得成功時
          // 配列を結合
          datas = datas.concat(res.value.diagnostics);
          if (res.value.next_page_token) {
            // レスポンスに next_page がある場合は次のリクエストを行う
            if (params) {
              params.set('page_token', res.value.next_page_token);
            }
            await splitRequest();
          }

          return 'success';
        };

        const result = await splitRequest();

        setLoading(false);

        if (result === 'error') {
          return [];
        }

        return datas;
      },
    [notifyError, request, t, getErrorMessage],
  );

  return {
    loading,
    getSyncDiagnostics,
  };
};

export const useSyncExcludeLevelDiagnosticsAPI = () => {
  const [loading, setLoading] = useState(false);
  const { getSyncDiagnostics } = useSyncDiagnosticsAPI();

  const getSyncExcludeLevelDiagnostics = useRecoilCallback(
    () =>
      async (
        vehicleId: string,
        excludeLevels: DiagnosticLevelString[],
        params: URLSearchParams,
      ) => {
        setLoading(true);
        const requestLevels = DIAGNOSTIC_LEVEL_STRING.filter(
          (level) => !excludeLevels.includes(level),
        );
        const results = await Promise.all(
          requestLevels.map(async (level) => {
            const requestParams = new URLSearchParams(params);
            requestParams.delete('level');
            requestParams.append('level', level.toString());
            const res = await getSyncDiagnostics(vehicleId, requestParams);
            return res;
          }),
        );

        const flatResults = results.flat();
        flatResults.sort((a, b) => {
          const aStartTime = DateTime.fromISO(a.start_timestamp);
          const bStartTime = DateTime.fromISO(b.start_timestamp);
          return aStartTime.toMillis() - bStartTime.toMillis();
        });

        setLoading(false);
        return flatResults;
      },
    [getSyncDiagnostics],
  );

  return {
    getSyncExcludeLevelDiagnostics,
    loading,
  };
};

const useDiagnosticValueHistoriesRequest = () => {
  const { datahubAPI } = useDatahubAPI({ cancelWhenUnmounting: true });

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
      params: URLSearchParams,
    ): Promise<Result<DiagnosticValueResponse, AxiosResponse>> => {
      try {
        const res = await datahubAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/diagnostics/values_history?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return axios.isCancel(error)
          ? new Success({
              value_history: [],
              page_size: 0,
            })
          : new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [datahubAPI],
  );

  return request;
};

export const useDiagnosticValueHistoriesAPI = (): {
  loading: boolean;
  valueHistories: DiagnosticValueHistory[];
  nextPageToken: string | undefined;
  getDiagnosticValueHistories: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<void>;
  getDiagnosticAllValueHistories: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<void>;
} => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const request = useDiagnosticValueHistoriesRequest();
  const [valueHistories, setValueHistories] = useState<
    DiagnosticValueHistory[]
  >([]);
  const [nextPageToken, setNextPageToken] = useState<string>();

  const getDiagnosticValueHistories = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_diagnostics', { status: 'failed' }),
          });
          setLoading(false);
          return;
        }

        const res = await request(
          project.id,
          environment.environment_id,
          vehicleId,
          params,
        );

        // 取得失敗時
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.datahub.get_diagnostics', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          return;
        }
        // 取得成功時
        if (!!res && res.isSuccess()) {
          // 配列を結合
          setValueHistories((prevState) =>
            prevState.concat(res.value.value_history),
          );
          setNextPageToken(res.value.next_page_token);
          setLoading(false);
        }
      },
    [request, notifyError, t, getErrorMessage],
  );

  const getDiagnosticAllValueHistories = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);

        setValueHistories([]);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_diagnostics', { status: 'failed' }),
          });
          setLoading(false);
          return;
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        const splitRequest = async (): Promise<void> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            notifyError({
              message: `${t('api.datahub.get_diagnostics', {
                status: 'failed',
              })}: ${getErrorMessage(res)}`,
            });
            return;
          }
          // 取得成功時
          if (!!res && res.isSuccess()) {
            // 配列を結合
            setValueHistories((prevState) =>
              prevState.concat(res.value.value_history),
            );
            if (res.value.next_page_token) {
              // レスポンスに next_page がある場合は次のリクエストを行う
              if (params) {
                params.set('page_token', res.value.next_page_token);
              }
              await splitRequest();
            } else {
              setLoading(false);
            }
          }
        };

        await splitRequest();

        setLoading(false);
      },
    [notifyError, request, t, getErrorMessage],
  );

  return {
    loading,
    nextPageToken,
    getDiagnosticValueHistories,
    getDiagnosticAllValueHistories,
    valueHistories,
  };
};

export const useSyncDiagnosticValueHistoriesAPI = (): {
  loading: boolean;
  getSyncDiagnosticValueHistories: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<DiagnosticValueHistory[]>;
} => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const request = useDiagnosticValueHistoriesRequest();

  const getSyncDiagnosticValueHistories = useRecoilCallback(
    ({ snapshot }) =>
      async (vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_diagnostics', { status: 'failed' }),
          });
          setLoading(false);
          return [];
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        const splitRequest = async (
          data: DiagnosticValueHistory[] = [],
        ): Promise<DiagnosticValueHistory[]> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            notifyError({
              message: `${t('api.datahub.get_diagnostics', {
                status: 'failed',
              })}: ${getErrorMessage(res)}`,
            });
            return data;
          }
          // 取得成功時
          if (!!res && res.isSuccess()) {
            // 配列を結合
            const combinedData = [...data, ...res.value.value_history];
            if (isNullOrUndefined(res.value.next_page_token)) {
              return combinedData;
            }
            // レスポンスに next_page_token がある場合は次のリクエストを行う
            params.set('page_token', res.value.next_page_token);
            return await splitRequest(combinedData);
          }

          return data;
        };

        const result = await splitRequest();
        setLoading(false);
        return result;
      },
    [request, notifyError, t, getErrorMessage],
  );

  return {
    loading,
    getSyncDiagnosticValueHistories,
  };
};
