import { useCallback, useState } from 'react';
import type { Result } from '@api';
import { Failure, Success, useErrorMessage, useDatahubAPI } from '@api';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import { useRecoilCallback } from 'recoil';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useTranslation } from 'react-i18next';
import type { BagFile, LogType, SyslogFile } from '@data/datahub/log/types';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

type APIResponse<T> = {
  bags?: BagFile[];
  log_files?: T[];
  page_size: number;
  next_page_token?: string;
};

export const useLogFileAPI = <T>(): {
  loading: boolean;
  files: T[];
  getBagFiles: (vehicleId: string, params: URLSearchParams) => Promise<void>;
  getSyslogFiles: (vehicleId: string, params: URLSearchParams) => Promise<void>;
  getTcpdumpFiles: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<void>;
  getCoredumpFiles: (
    vehicleId: string,
    params: URLSearchParams,
  ) => Promise<void>;
  downloadSyslogFiles: (syslogFiles: SyslogFile[]) => Promise<void>;
} => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<T[]>([]);
  const { notifyError } = useNotification();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();
  const { datahubAPI } = useDatahubAPI({ cancelWhenUnmounting: true });
  const getHasScope = useHasScope();

  const request = useCallback(
    async (
      type: LogType,
      projectId: string,
      environmentId: string,
      vehicleId: string,
      params: URLSearchParams,
    ): Promise<Result<APIResponse<T>, AxiosResponse>> => {
      const path = type === 'bag' ? 'bags' : `log_files/${type}`;
      try {
        const res = await datahubAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/${path}?${params.toString()}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return axios.isCancel(error)
          ? new Success({
              vehicles: [],
              page_size: 0,
            })
          : new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [datahubAPI],
  );

  const getLogFiles = useRecoilCallback(
    ({ snapshot }) =>
      async (type: LogType, vehicleId: string, params: URLSearchParams) => {
        setLoading(true);
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        const capitalizedLogType = `${type.charAt(0).toUpperCase()}${type.slice(
          1,
        )}`;

        setFiles([]);

        if (!project || !environment) {
          notifyError({
            message: t('api.datahub.get_log_files', {
              status: 'failed',
              logType: capitalizedLogType,
            }),
          });
          setLoading(false);
          return;
        }

        const req = async (requestParams: URLSearchParams) =>
          await request(
            type,
            project.id,
            environment.environment_id,
            vehicleId,
            requestParams,
          );

        const splitRequest = async (): Promise<void> => {
          const res = await req(params);
          // 取得失敗時
          if (!res.value || res.isFailure()) {
            // リクエストに失敗した場合
            notifyError({
              message: `${t('api.datahub.get_log_files', {
                status: 'failed',
                logType: capitalizedLogType,
              })}: ${getErrorMessage(res)}`,
            });
            return;
          }
          // 取得成功時
          if (!!res && res.isSuccess()) {
            // 配列を結合
            setFiles((prevState) =>
              prevState.concat(
                type === 'bag'
                  ? (res.value.bags as T[])
                  : (res.value.log_files as T[]),
              ),
            );
            if (res.value.next_page_token) {
              // レスポンスに next_page_token がある場合は次のリクエストを行う
              if (params) {
                params.set('page_token', res.value.next_page_token);
              }
              await splitRequest();
            }
          } else {
            setLoading(false);
          }
        };

        await splitRequest();

        setLoading(false);
      },
    [notifyError, request, t, getErrorMessage],
  );

  const getBagFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope(SCOPES.DescribeBag)) return;
      await getLogFiles('bag', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  const getSyslogFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope(SCOPES.DescribeLogFile)) return;
      await getLogFiles('syslog', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  const getTcpdumpFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope(SCOPES.DescribeLogFile)) return;
      await getLogFiles('tcpdump', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  const getCoredumpFiles = useCallback(
    async (vehicleId: string, params: URLSearchParams) => {
      if (!getHasScope(SCOPES.DescribeLogFile)) return;
      await getLogFiles('coredump', vehicleId, params);
    },
    [getLogFiles, getHasScope],
  );

  const downloadSyslogFiles = useCallback(
    async (syslogFiles: SyslogFile[]) => {
      const zip = new JSZip();
      await Promise.all(
        syslogFiles.map(async (file) => {
          try {
            const res = await axios.get(file.download_url, {
              responseType: 'blob',
            });
            // 圧縮するファイルに追加
            zip.file(file.file_name, new Blob([res.data]));
          } catch (err) {
            notifyError({
              message: `${t('api.datahub.download_syslog_files', {
                status: 'failed',
              })}: ${err}`,
            });
          }
        }),
      );
      // ZIP に圧縮
      try {
        const blob = await zip.generateAsync({ type: 'blob' });
        // ダウンロード
        saveAs(blob, 'syslog.zip');
      } catch (err) {
        notifyError({
          message: `${t('api.datahub.download_syslog_files', {
            status: 'failed',
          })}: ${err}`,
        });
      }
    },
    [notifyError, t],
  );

  return {
    files,
    loading,
    getBagFiles,
    getSyslogFiles,
    getTcpdumpFiles,
    getCoredumpFiles,
    downloadSyslogFiles,
  };
};
