import { atom } from 'recoil';
import type { Timezone } from './types';

export const defaultTimezone: Timezone = {
  time_difference_from_utc: '+09:00',
  timezone: 'Asia/Tokyo',
};

export const timezoneAtom = atom<Timezone>({
  key: 'timezoneAtom',
  default: defaultTimezone,
});
