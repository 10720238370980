import { atom, selector } from 'recoil';
import type { MaintenanceEvent } from './types';

export const maintenanceEventsAtom = atom<MaintenanceEvent[]>({
  key: 'maintenanceEventsAtom',
  default: [],
});

// 表示用のメンテ情報
export const displayMaintenanceEventSelector = selector({
  key: 'displayMaintenanceEventSelector',
  get: ({ get }) => {
    const events = get(maintenanceEventsAtom);
    // インシデントレベルが informational && ステータスが canceled or completed
    // の場合は表示しない
    const event = events.find(
      ({ incident_level, status }) =>
        incident_level !== 'informational' &&
        status !== 'canceled' &&
        status !== 'completed',
    );
    if (!event) return null;
    return event;
  },
});

// メンテ情報を表示しているときとしていないときの Header Height
export const headerHeightSelector = selector({
  key: 'headerHeightSelector',
  get: ({ get }) => {
    const displayMaintenanceEvent = get(displayMaintenanceEventSelector);
    if (displayMaintenanceEvent) {
      return 102;
    }
    return 64;
  },
});
