import { useErrorMessage } from '@api';
import {
  FEATURE_SCOPES,
  hasCreateEnvironmentScopeAtom,
  hasDescribeAreaMapScopeAtom,
  hasDescribeAreaMapServiceQuotaScopeAtom,
  hasDownloadAreaMapScopeAtom,
  projectAtom,
  SCOPES,
} from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import axios from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import { usePostAuthorizationAPI } from '.';
import type { APIState, Result } from '..';
import { authAPI, Failure, Success } from '..';

type AssertRequestParam = {
  token: string;
  feature_scope?: string;
  scope?: string;
};

type AssertResponse = {
  result: boolean;
  scope: string;
  missing_scope: string;
  feature_scope: string;
  missing_feature_scope: string;
};

type AssertResult = {
  feature_scope: string;
  scope: string;
};

type AssertAreaMapResult = {
  describe: boolean;
  describeServiceQuota: boolean;
};

export const usePostAssertAPI = (): {
  state: APIState;
  postAssertAreaMap: (
    projectId?: string,
    areaMapId?: number,
  ) => Promise<AssertAreaMapResult>;
  postAssertCreateEnvironment: (projectId: string) => Promise<boolean>;
  postAssert: () => Promise<AssertResult | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const getToken = usePostAuthorizationAPI();
  const { notifyError } = useNotification();
  const setHasCreateEnvironmentScope = useSetRecoilState(
    hasCreateEnvironmentScopeAtom,
  );
  const setHasDescribeAreaMapScope = useSetRecoilState(
    hasDescribeAreaMapScopeAtom,
  );
  const setHasDownloadAreaMapScope = useSetRecoilState(
    hasDownloadAreaMapScopeAtom,
  );
  const setHasDescribeAreaMapServiceQuotaScope = useSetRecoilState(
    hasDescribeAreaMapServiceQuotaScopeAtom,
  );
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const postAssertAPI = useCallback(
    async (
      param: AssertRequestParam,
    ): Promise<Result<AssertResponse, AxiosResponse>> => {
      try {
        const res = await authAPI.post('/assert', param);
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [],
  );

  /**
   * 走行環境作成権限があるかどうをチェックする
   * ヘッダーでプロジェクト切替時に「走行環境追加」ボタンの表示判定に使用
   */
  const postAssertCreateEnvironment = useCallback(
    async (projectId: string) => {
      const token = await getToken();
      if (!token) {
        throw new axios.Cancel('token is null');
      }
      const paramScope = `${projectId}:environment:*=Create`;
      const res = await postAssertAPI({
        token: token.accessToken,
        scope: paramScope,
      });
      if (!res.value || res.isFailure()) {
        setState('hasError');
        notifyError({
          message: `${t('api.auth.post_assert', {
            status: 'failed',
          })}: ${getErrorMessage(res)}`,
        });
        setHasCreateEnvironmentScope(false);
        return false;
      }
      setHasCreateEnvironmentScope(res.value.result);
      return res.value.result;
    },
    [
      getToken,
      postAssertAPI,
      notifyError,
      setHasCreateEnvironmentScope,
      getErrorMessage,
      t,
    ],
  );

  /**
   * エリアマップ情報取得権限があるかどうかをチェック
   */
  const postAssertAreaMap = useRecoilCallback(
    ({ snapshot }) =>
      async (projectId: string | undefined, areaMapId: number | undefined) => {
        const token = await getToken();
        setState('loading');
        if (!token) {
          throw new axios.Cancel('token is null');
        }
        let paramProjectId = projectId;
        let paramAreaMapId = areaMapId;
        if (isNullOrUndefined(projectId)) {
          const project = await snapshot.getPromise(projectAtom);
          if (!isNullOrUndefined(project)) {
            paramProjectId = project.id;
          }
        }
        if (isNullOrUndefined(areaMapId)) {
          const environment = await snapshot.getPromise(environmentAtom);
          if (!isNullOrUndefined(environment)) {
            paramAreaMapId = environment.area_map_id;
          }
        }

        const prefix = `${paramProjectId}:area_map:${paramAreaMapId}`;
        const describe = `${prefix}=Describe`;
        const download = `${prefix}=Download`;
        const describeServiceQuota = `${prefix}=DescribeServiceQuota`;
        const paramScope = `${describe} ${download} ${describeServiceQuota}`;
        const res = await postAssertAPI({
          token: token.accessToken,
          scope: paramScope,
        });
        if (!res.value || res.isFailure()) {
          setState('hasError');
          notifyError({
            message: `${t('api.auth.post_assert', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setHasDescribeAreaMapScope(false);
          setHasDescribeAreaMapServiceQuotaScope(false);
          return {
            describe: false,
            describeServiceQuota: false,
          };
        }
        setState('hasValue');

        const hasDescribeScope = res.value.scope.includes(describe);
        const hasDownloadScope = res.value.scope.includes(download);
        const hasDescribeServiceQuotaScope =
          res.value.scope.includes(describeServiceQuota);

        setHasDescribeAreaMapScope(hasDescribeScope);
        setHasDownloadAreaMapScope(hasDownloadScope);
        setHasDescribeAreaMapServiceQuotaScope(hasDescribeServiceQuotaScope);

        return {
          describe: hasDescribeScope,
          describeServiceQuota: hasDescribeServiceQuotaScope,
        };
      },
    [
      getToken,
      postAssertAPI,
      notifyError,
      setHasDescribeAreaMapScope,
      setHasDownloadAreaMapScope,
      setHasDescribeAreaMapServiceQuotaScope,
      getErrorMessage,
      t,
    ],
  );

  /**
   * 走行環境作成と、エリアマップバージョン情報取得以外の scope, feature_scope を検証
   */
  const postAssert = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        setState('loading');
        const token = await getToken();
        if (!token) {
          throw new axios.Cancel('token is null');
        }
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.auth.post_assert', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        const requestFeatureScope = () => {
          const prefix = `${project!.id}:fms`;
          return Object.values(FEATURE_SCOPES)
            .map((key) => `${prefix}:${key}`)
            .join(' ');
        };

        const MAX_SCOPE_COUNT = 25;
        const scopePrefix = `${project!.id}:environment:${
          environment!.environment_id
        }`;
        const scopeValues = Object.values(SCOPES);
        const scopeRequestCount = Math.ceil(
          scopeValues.length / MAX_SCOPE_COUNT,
        );
        const scopeRequestCountArr = [...Array(scopeRequestCount)].map(
          (_, i) => i,
        );
        let resFeatureScope = '';
        let resScope = '';
        const splitRequest = async () => {
          for (const i of scopeRequestCountArr) {
            const filteredScopes = scopeValues.filter(
              (_, j) =>
                i * MAX_SCOPE_COUNT <= j && j < (i + 1) * MAX_SCOPE_COUNT,
            );
            const paramsScope = filteredScopes
              .map((scope) => `${scopePrefix}=${scope}`)
              .join(' ');
            const requestBody = {
              token: token.accessToken,
              scope: paramsScope,
              feature_scope: i === 0 ? requestFeatureScope() : '',
            };
            const res = await postAssertAPI(requestBody);
            if (!res.value || res.isFailure()) {
              setState('hasError');
              notifyError({
                message: `${t('api.auth.post_assert', {
                  status: 'failed',
                })}: ${getErrorMessage(res)}`,
              });
            } else {
              if (i === 0) resFeatureScope = res.value.feature_scope;
              resScope += i === 0 ? res.value.scope : ` ${res.value.scope}`;
            }
          }
        };
        await splitRequest();

        setState('hasValue');

        return {
          scope: resScope,
          feature_scope: resFeatureScope,
        };
      },
    [getToken, postAssertAPI, notifyError, t, getErrorMessage],
  );

  return {
    state,
    postAssert,
    postAssertCreateEnvironment,
    postAssertAreaMap,
  };
};
