import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';

const CoverLoader: React.FC = () => (
  <WrapperBox>
    <CircularProgress />
  </WrapperBox>
);

export default CoverLoader;

const WrapperBox = styled(Box)`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 10000;
`;
