import type { APIState, Result } from '@api';
import { Failure, useFMSAPI, Success, useErrorMessage } from '@api';
import { projectAtom } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import type { Environment } from '@data/fms/environment/types';
import { useNotification } from '@data/notification';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

/**
 * 単一走行環境情報取得API
 */
export const useEnvironmentAPI = (): {
  state: APIState;
  getEnvironment: (environmentId: string) => Promise<Environment | null>;
  getCurrentEnvironment: () => Promise<Environment | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
    ): Promise<Result<Environment, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  /**
   * 指定IDの走行環境情報取得
   */
  const getEnvironment = useRecoilCallback(
    ({ snapshot }) =>
      async (environmentId: string) => {
        const project = await snapshot.getPromise(projectAtom);
        if (!project) {
          notifyError({
            message: t('api.fms.get_environments', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await request(project!.id, environmentId);
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_environments', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
    [notifyError, request, t, getErrorMessage],
  );

  /**
   * 現在の走行環境情報取得
   */
  const getCurrentEnvironment = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (!project || !environment) {
          notifyError({
            message: t('api.fms.get_environments', { status: 'failed' }),
          });
          setState('hasError');
          return null;
        }
        setState('loading');
        const res = await request(project!.id, environment.environment_id);
        if (!res.value || res.isFailure()) {
          notifyError({
            message: `${t('api.fms.get_environments', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
    [notifyError, request, t, getErrorMessage],
  );

  return {
    state,
    getEnvironment,
    getCurrentEnvironment,
  };
};

/**
 * プロジェクト内の走行環境一覧取得API
 */
export const useEnvironmentsAPI = (): {
  state: APIState;
  getEnvironments: (projectId: string) => Promise<Environment[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getEnvironmentsAPI = useCallback(
    async (
      projectId: string,
    ): Promise<Result<Environment[], AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(`/${projectId}/environments`);
        if (!Array.isArray(res?.data)) return new Success([]);
        // 昇順ソート
        res?.data.sort((a: Environment, b: Environment) => {
          if (a.environment_name < b.environment_name) return -1;
          return 1;
        });
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getEnvironments = useCallback(
    async (projectId: string) => {
      setState('loading');
      const res = await getEnvironmentsAPI(projectId);
      if (isNullOrUndefined(res.value) || res.isFailure()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((res as any).value?.status !== 404) {
          notifyError({
            message: `${t('api.fms.get_environments', {
              status: 'failed',
            })}: ${getErrorMessage(res)}`,
          });
        }
        setState('hasError');
        return [];
      }
      setState('hasValue');
      return res.value;
    },
    [notifyError, getEnvironmentsAPI, t, getErrorMessage],
  );

  return {
    state,
    getEnvironments,
  };
};
