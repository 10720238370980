import {
  Box,
  Icon,
  IconButton as MuiIconButton,
  Typography,
  styled,
  colors,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { AutoSizer, Column, Table } from 'react-virtualized';
import DataCell, { DataCellBox } from '../../atoms/DataCell';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { ProbeNotGenerated } from '..';
import { useNavigate, useParams } from 'react-router-dom';
import { useRootPath } from '@data/fms/environment/hooks';
import { useHasScope, SCOPES } from '@data/auth';
import type { SetterOrUpdater } from 'recoil';
import type { HistoryData } from '@components/pages/DrivingHistory/types';
import type { ManualOverrideTimestamp } from '@data/datahub/manualOverride/types';
import { Minus } from 'react-feather';

type DateProps = {
  date: string;
};

const Date: React.FC<DateProps> = React.memo(({ date }: DateProps) => {
  const dateText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('yyyy/MM/dd');
  }, [date]);

  const timeText = useMemo(() => {
    if (!date) return null;
    return DateTime.fromISO(date).toFormat('HH:mm:ss');
  }, [date]);

  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {dateText}
      </Typography>
      <Typography>{timeText}</Typography>
    </Box>
  );
});

Date.displayName = 'Date';

type Props = {
  data: HistoryData;
  isProbeGenerated: boolean;
  setData: SetterOrUpdater<HistoryData>;
  height: number;
  showCreateMemoButton?: boolean;
};

const OverrideList: React.FC<Props> = ({
  data,
  isProbeGenerated,
  setData,
  height,
  showCreateMemoButton = true,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const rootPath = useRootPath();
  const params = useParams<{ vehicle_id: string }>();
  const getHasScope = useHasScope();

  const enableCreateMemo = useMemo(
    () => showCreateMemoButton && getHasScope(SCOPES.CreateDrivingNote),
    [getHasScope, showCreateMemoButton],
  );

  const columns = useMemo(() => {
    const dateCellWidth = enableCreateMemo ? 366 : 446;
    const arr = [
      // 562
      {
        label: 'No',
        dataKey: 'no',
        width: 56,
      },
      {
        label: t('common.date.time'),
        dataKey: 'time',
        width: dateCellWidth,
      },
      {
        label: 'Map',
        dataKey: 'map',
        width: 70,
      },
    ];
    if (enableCreateMemo) {
      return [
        ...arr,
        {
          label: t('driving_memo.category.memo'),
          dataKey: 'memo',
          width: 70,
        },
      ];
    }
    return arr;
  }, [t, enableCreateMemo]);

  const handleClickMapIcon = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const index = Number(e.currentTarget.dataset.index);
      setData((prevState) => ({
        ...prevState,
        selectedManualOverride: {
          no: index + 1,
          data: prevState.manualOverrideTimestamps[index],
        },
      }));
    },
    [setData],
  );

  const handleClickCreateMemo = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      const date = e.currentTarget.dataset.errorTime;
      if (!date) return;
      const dateLuxon = DateTime.fromISO(date);
      if (!dateLuxon.isValid) return;
      navigate(
        `${rootPath}driving-note-register/${
          params.vehicle_id
        }/?category=bug&date=${dateLuxon.toUnixInteger()}`,
      );
      console.log('click');
    },
    [navigate, rootPath, params.vehicle_id],
  );

  const headerRenderer = useCallback(
    ({ label }: { label: React.ReactNode }) => {
      return (
        <DataCell variant="head" align="left" component="div">
          <span>{label}</span>
        </DataCell>
      );
    },
    [],
  );

  const cellRenderer = useCallback(
    ({
      dataKey,
      rowData,
      rowIndex,
    }: {
      dataKey: string;
      rowData: ManualOverrideTimestamp;
      rowIndex: number;
    }) => {
      const getCellData = () => {
        if (dataKey === 'no') return <DataCellBox>{rowIndex + 1}</DataCellBox>;
        if (dataKey === 'time') {
          return (
            <DataCellBox data-testid="override_time">
              <Date date={rowData.start_timestamp} />
              <Box mx={2} mt={6.5}>
                <Minus size={14} color={colors.grey[500]} />
              </Box>
              <Date date={rowData.end_timestamp} />
            </DataCellBox>
          );
        }
        if (dataKey === 'map') {
          return (
            <DataCellBox>
              <Tooltip
                arrow
                placement="top"
                title={
                  data.probeLoading ? t('driving_history.probe_loading') : ''
                }
              >
                <span>
                  <IconButton
                    size="small"
                    data-index={rowIndex}
                    disabled={data.probeLoading}
                    onClick={handleClickMapIcon}
                    data-testid="map_icon"
                  >
                    <Icon>room</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            </DataCellBox>
          );
        }
        if (dataKey === 'memo') {
          return (
            <DataCellBox>
              <IconButton
                size="small"
                data-index={rowIndex}
                data-error-time={rowData.start_timestamp}
                onClick={handleClickCreateMemo}
                data-testid="memo_icon"
              >
                <Icon>create</Icon>
              </IconButton>
            </DataCellBox>
          );
        }
      };

      return (
        <DataCell component="div" sx={{ height: 60 }}>
          {getCellData()}
        </DataCell>
      );
    },
    [handleClickMapIcon, handleClickCreateMemo, data.probeLoading, t],
  );

  if (!isProbeGenerated) {
    return (
      <Box width="100%" height={height}>
        <ProbeNotGenerated />
      </Box>
    );
  }

  return (
    <Box width="100%" height={height}>
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={45}
            rowCount={data.manualOverrideTimestamps.length}
            rowGetter={({ index }) => data.manualOverrideTimestamps[index]}
            rowHeight={60}
            columns={columns}
          >
            {columns.map(({ dataKey, ...other }) => (
              <Column
                key={dataKey}
                dataKey={dataKey}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    label: headerProps.label,
                  })
                }
                cellRenderer={({ dataKey, rowData, rowIndex }) =>
                  cellRenderer({
                    dataKey,
                    rowData,
                    rowIndex,
                  })
                }
                {...other}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Box>
  );
};

export default React.memo(OverrideList);

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;
