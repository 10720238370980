import React from 'react';
import { useRecoilValue } from 'recoil';
import { ReloadRequiredDialog } from '@components/common/molecules';
import { isWebsocketErrorAtom } from '@data/common/webSocket/states';

const WebsocketErrorDialog: React.FC = () => {
  const isWebsocketError = useRecoilValue(isWebsocketErrorAtom);

  return (
    <ReloadRequiredDialog
      isOpen={isWebsocketError}
      title={'dialog.websocket_error.title'}
      message={'dialog.websocket_error.message'}
    />
  );
};

export default WebsocketErrorDialog;
