import type { AlertColor } from '@mui/material';
import {
  IconButton,
  Popover,
  Grid,
  Box,
  Link,
  Typography,
  Divider,
  List,
  ListItem as MuiListItem,
  styled,
  Alert as MuiAlert,
  AlertTitle,
  Tooltip,
  Badge,
} from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useCallback, useState } from 'react';
import { Bell } from 'react-feather';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Close, Info } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import type { VehicleAlert } from '@data/notification/vehicle/types';
import {
  useVehicleAlertContent,
  vehicleAlertListAtom,
} from '@data/notification/vehicle';

type AlertItemProps = {
  no: number;
  alert: VehicleAlert;
};

const AlertItem: React.FC<AlertItemProps> = ({ no, alert }: AlertItemProps) => {
  const setVehicleAlertList = useSetRecoilState(vehicleAlertListAtom);
  const { title, level, vehicleName, message } = useVehicleAlertContent(alert);

  /**
   * Close ボタンクリック
   */
  const handleClickCloseButton = useCallback(() => {
    setVehicleAlertList((prevState) => prevState.filter((_, i) => i !== no));
  }, [no, setVehicleAlertList]);

  return (
    <ListItem disableGutters>
      <MuiAlert
        severity={level as AlertColor}
        action={
          <IconButton
            color="inherit"
            size="small"
            onClick={handleClickCloseButton}
          >
            <Close fontSize="inherit" />{' '}
          </IconButton>
        }
      >
        <AlertTitle>{title}</AlertTitle>
        <Typography variant="caption">{alert.notifiedAt}</Typography>
        <Typography>{vehicleName}</Typography>
        {message}
      </MuiAlert>
    </ListItem>
  );
};

const ListItem = styled(MuiListItem)`
  .MuiAlert-root {
    width: 100%;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
`;

const HeaderVehicleAlertList: React.FC = () => {
  const [vehicleAlertList, setVehicleAlertList] =
    useRecoilState(vehicleAlertListAtom);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();

  /**
   * ベルアイコンボタンクリック
   */
  const handleClickIconButton = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    },
    [],
  );

  /**
   * Popover を閉じる
   */
  const handleClosePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  /**
   * すべてクリアするボタンクリック
   */
  const handleClickAllClearButton = useCallback(() => {
    setVehicleAlertList([]);
  }, [setVehicleAlertList]);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleClickIconButton}
        data-testid="alertInfo-icon"
      >
        <Badge
          variant="dot"
          color="error"
          invisible={vehicleAlertList.length === 0}
        >
          <Bell />
        </Badge>
      </IconButton>
      <Popover
        open={!isNullOrUndefined(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box width={400} p={4} minHeight={200} maxHeight={600}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="h6">
                    {t('notification.vehicle_alert_list.title')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip
                    arrow
                    title={t('notification.vehicle_alert_list.tooltip')}
                  >
                    <span>
                      <Info fontSize="small" color="info" />
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            {vehicleAlertList.length > 0 && (
              <Grid item>
                <Link
                  component="button"
                  color="textSecondary"
                  onClick={handleClickAllClearButton}
                >
                  {t('notification.vehicle_alert_list.clear_all')}
                </Link>
              </Grid>
            )}
          </Grid>
          <Box mt={2} mb={2}>
            <Divider />
          </Box>
          <Box>
            {vehicleAlertList.length === 0 ? (
              <Box mt={14}>
                <Typography textAlign="center" color="GrayText">
                  {t('notification.vehicle_alert_list.none')}
                </Typography>
              </Box>
            ) : (
              <List disablePadding>
                {vehicleAlertList.map((alert, i) => (
                  <AlertItem key={`alert_${i}`} no={i} alert={alert} />
                ))}
              </List>
            )}
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderVehicleAlertList;
