import { atom } from 'recoil';
import type { User } from '@tier4/webauto-auth-frontend-client';

export const isAuthErrorAtom = atom({
  key: 'isAuthErrorAtom',
  default: false,
});

export const userAtom = atom<User | null>({
  key: 'userAtom',
  default: null,
});
