import type { APIState, Result } from '../';
import { Failure, Success, useFMSAPI, useErrorMessage } from '../';
import { useCallback, useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { useNotification } from '@data/notification';
import type { AxiosResponse, AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { environmentAtom } from '@data/fms/environment/states';
import type { PartialRoute } from '@data/fms/route/types';

export type PartialRouteSearchRequestParams = Partial<{
  origin_point: number;
  destination_point: number;
  origin_lanelet_id: number;
  destination_lanelet_id: number;
  start_time?: string;
}>;

export const usePartialRouteSearchAPI = (): {
  state: APIState;
  getPartialRoutesSearch: (
    params: PartialRouteSearchRequestParams[],
    isShowError?: boolean,
  ) => Promise<(PartialRoute | null)[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      params: PartialRouteSearchRequestParams,
    ): Promise<Result<PartialRoute, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/partial_route_search`,
          {
            params,
          },
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getPartialRoutesSearch = useRecoilCallback(
    ({ snapshot }) =>
      async (
        paramsList: PartialRouteSearchRequestParams[],
        isShowError = true,
      ) => {
        setState('loading');
        if (!getHasScope(SCOPES.SearchPartialRoute)) {
          setState('hasError');
          return [];
        }
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (!project || !environment) {
          if (isShowError) {
            notifyError({
              message: t('api.fms.get_route_search', { status: 'failed' }),
            });
          }
          setState('hasError');
          return [];
        }

        const results: (PartialRoute | null)[] = [];
        let index = 0;
        const requestNum = paramsList.length;
        const initialTime = DateTime.fromISO(paramsList[0].start_time!);

        const serialRequest = async () => {
          const params = paramsList[index];

          const startTime = initialTime
            .plus({
              seconds: results.reduce((prev, current) => {
                if (current && current.eta_sec) {
                  return prev + current.eta_sec;
                }
                return prev;
              }, 0),
            })
            .toJSDate()
            .toISOString();

          params.start_time = startTime;

          const res = await request(
            project.id,
            environment.environment_id,
            params,
          );
          if (!res.value || res.isFailure()) {
            if (isShowError) {
              notifyError({
                message: `${t('api.fms.get_route_search', {
                  status: 'failed',
                })}: ${getErrorMessage(res)}`,
              });
            }
            results.push(null);
          } else {
            results.push(res.value);
          }

          if (index < requestNum - 1) {
            index += 1;
            await serialRequest();
          }
        };

        await serialRequest();

        setState('hasValue');
        return results;
      },
    [notifyError, request, getHasScope, t, getErrorMessage],
  );

  return {
    state,
    getPartialRoutesSearch,
  };
};

export type PartialRouteSearchFromCurrentPositionRequestParams = Partial<{
  destination_lanelet_id: number;
  start_time?: string;
}>;

export const usePartialRouteSearchFromCurrentPositionAPI = (): {
  state: APIState;
  getPartialRouteSearchFromCurrentPosition: (
    vehicleId: string,
    params: PartialRouteSearchFromCurrentPositionRequestParams,
    isShowError?: boolean,
  ) => Promise<PartialRoute | null>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const { t } = useTranslation();
  const getErrorMessage = useErrorMessage();

  const request = useCallback(
    async (
      projectId: string,
      environmentId: string,
      vehicleId: string,
      params: PartialRouteSearchFromCurrentPositionRequestParams,
    ): Promise<Result<PartialRoute, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/vehicles/${vehicleId}/partial_route_search`,
          {
            params,
          },
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getPartialRouteSearchFromCurrentPosition = useRecoilCallback(
    ({ snapshot }) =>
      async (
        vehicleId: string,
        params: PartialRouteSearchFromCurrentPositionRequestParams,
        isShowError = true,
      ) => {
        setState('loading');
        if (!getHasScope(SCOPES.SearchPartialRoute)) {
          setState('hasError');
          return null;
        }
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (!project || !environment) {
          if (isShowError) {
            notifyError({
              message: t('api.fms.get_route_search_current_position', {
                status: 'failed',
              }),
            });
          }
          setState('hasError');
          return null;
        }
        const res = await request(
          project.id,
          environment.environment_id,
          vehicleId,
          params,
        );
        if (!res || res.isFailure()) {
          if (isShowError) {
            notifyError({
              message: `${t('api.fms.get_route_search', {
                status: 'failed',
              })}: ${getErrorMessage(res)}`,
            });
          }
          setState('hasError');
          return null;
        }
        setState('hasValue');
        return res.value;
      },
    [notifyError, request, getHasScope, t, getErrorMessage],
  );

  return {
    state,
    getPartialRouteSearchFromCurrentPosition,
  };
};
