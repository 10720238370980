import { laneletsSelector } from '@data/fms/lanelet/states';
import { mapMenuAtom } from '@data/ui/states';
import { hasCurrentAreaMapVersionLaneletsSelector } from '@data/fms/areamap/states';
import { useLaneletsCenterPoints } from '@data/fms/lanelet/hooks';
import { colors } from '@mui/material';
import { Polyline } from '@react-google-maps/api';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

const LaneletPolylines: React.FC = () => {
  const mapMenu = useRecoilValue(mapMenuAtom);
  const lanelets = useRecoilValue(laneletsSelector);
  const { updateToLatestLanelets } = useLaneletsCenterPoints();
  const hasCurrentAreaMapVersionLanelets = useRecoilValue(
    hasCurrentAreaMapVersionLaneletsSelector,
  );

  useEffect(() => {
    if (mapMenu.laneletsCenterPoints) updateToLatestLanelets();
  }, [mapMenu.laneletsCenterPoints, updateToLatestLanelets]);

  if (!mapMenu.laneletsCenterPoints || !hasCurrentAreaMapVersionLanelets)
    return null;

  return (
    <>
      {Object.keys(lanelets).map((key) => (
        <Polyline
          key={`lane_${key}`}
          path={lanelets[key]}
          options={{
            clickable: false,
            icons: [
              {
                icon: {
                  path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW,
                },
                offset: '100%',
                repeat: '60px',
              },
            ],
            strokeColor: colors.blueGrey[700],
            strokeWeight: 2,
            zIndex: 0,
          }}
        />
      ))}
    </>
  );
};

export default LaneletPolylines;
