import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import type { Project } from '@data/auth';
import {
  projectAtom,
  assertAtom,
  projectMembersAtom,
  projectsAtom,
  userAtom,
  isAuthErrorAtom,
} from '@data/auth';
import { Loader } from '@components/common/atoms';
import Layout from '@components/common/templates/Layout';
import { Box, CircularProgress, Paper, styled } from '@mui/material';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import {
  AuthErrorDialog,
  ProjectEnvironmentDialog,
  VehicleAlertNotification,
  WeatherNotification,
  WebsocketErrorDialog,
} from '@components/common/organisms';
import { useMount, usePrevious } from 'react-use';
import store from 'store2';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import {
  AuthenticationManager,
  useGetProjectMembersAPI,
  usePostAssertAPI,
  useProjectsAPI,
} from '@api/auth';
import {
  useEnvironmentsAPI,
  useWebAutoApplicationEnvironmentOptionAPI,
  usePlacesAPI,
  useVehiclesAPI,
  useEnvironmentAreaMapVersionsAPI,
} from '@api/fms';
import { Settings as Config } from 'luxon';
import { oidcErrorDescription } from '@utils/oidc';
import { useNotification } from '@data/notification';
import type { RouteInfoType } from './types';
import { useFilteredRoutes } from './states';
import SigninCallback from '@pages/SigninCallback';
import { useWeatherLocationsAPI } from '@api/datahub/weather/getWeatherLocations';
import {
  useWeatherInfo,
  weatherForecastsAtom,
  weatherLocationAtom,
} from '@data/datahub/weather';
import { useVehiclesDrivenAPI } from '@api/datahub/getVehiclesDriven';
import { vehiclesDrivenAtom } from '@data/datahub/vehicleDriven';
import { ErrorBoundary } from 'react-error-boundary';
import { GlobalErrorDialog } from '@components/common/organisms';
import { setAnalysisStorage } from '@components/pages/Analysis/utils';
import { setVarianceStorage } from '@components/pages/PlanActualVariance/utils';
import { useTimezoneAPI } from '@api/datahub';
import { timezoneAtom } from '@data/datahub/timezone/states';
import { areaMapVersionsAtom } from '@data/fms/areamap/states';
import { useRootPath } from '@data/fms/environment/hooks';
import {
  environmentAtom,
  environmentsAtom,
  environmentOptionAtom,
  environmentChangeLoadingAtom,
} from '@data/fms/environment/states';
import { laneletsCenterLinePointsAtom } from '@data/fms/lanelet/states';
import type { Environment } from '@data/fms/environment/types';
import { setDrivingHistoryStorage } from '@components/pages/DrivingHistory/utils';
import { useVehiclesDataWebSocket } from '@data/fms/vehicle/hooks';
import { vehiclesAtom } from '@data/fms/vehicle/states';
import { placesAtom } from '@data/fms/place/states';

const NotFound = React.lazy(
  () => import(/* viteChunkName: 'notfound' */ '@pages/NotFound'),
);

const enableGA =
  !isNullOrUndefined(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID) &&
  import.meta.env.VITE_ENV_TYPE === 'prd';

const EnvironmentRoutes: React.FC = () => {
  const routes = useFilteredRoutes();
  const rootPath = useRootPath();
  return (
    <ErrorBoundary FallbackComponent={GlobalErrorDialog}>
      <Routes>
        {routes.map((route: RouteInfoType, index: number) => (
          <Route
            key={index}
            path={`${rootPath}${route.path}`}
            element={
              <React.Suspense fallback={<Loader />}>
                <route.component />
              </React.Suspense>
            }
          />
        ))}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Routes>
    </ErrorBoundary>
  );
};

const Routing: React.FC = () => {
  const user = useRecoilValue(userAtom);
  const [initialSelected, setInitialSelected] = useState(false);
  const [savedDataValidState, setSavedDataValidState] = useState('init');
  const { getProjects } = useProjectsAPI();
  const { getEnvironments } = useEnvironmentsAPI();
  const { getWebAutoApplicationEnvironmentOption } =
    useWebAutoApplicationEnvironmentOptionAPI();
  const { getEnvironmentAreaMapVersions } = useEnvironmentAreaMapVersionsAPI();
  const { getVehiclesDriven } = useVehiclesDrivenAPI();
  const [project, setProject] = useRecoilState(projectAtom);
  const [environment, setEnvironment] = useRecoilState(environmentAtom);
  const prevProject = usePrevious(project);
  const prevEnvironment = usePrevious(environment);
  const setVehicles = useSetRecoilState(vehiclesAtom);
  const setEnvironmentOption = useSetRecoilState(environmentOptionAtom);
  const location = useLocation();
  const { postAssert, postAssertAreaMap } = usePostAssertAPI();
  const [assert, setAssert] = useRecoilState(assertAtom);
  const prevAssert = usePrevious(assert);
  const { getPlaces } = usePlacesAPI();
  const setPlaces = useSetRecoilState(placesAtom);
  const setAreaMapVersions = useSetRecoilState(areaMapVersionsAtom);
  const { getProjectMembers } = useGetProjectMembersAPI();
  const setProjectMembers = useSetRecoilState(projectMembersAtom);
  const setProjects = useSetRecoilState(projectsAtom);
  const setEnvironments = useSetRecoilState(environmentsAtom);
  const { getTimezone } = useTimezoneAPI();
  const setTimezone = useSetRecoilState(timezoneAtom);
  const { getWeatherLocations } = useWeatherLocationsAPI();
  const setWeatherLocation = useSetRecoilState(weatherLocationAtom);
  const setWeatherForecasts = useSetRecoilState(weatherForecastsAtom);
  const getWeatherInfo = useWeatherInfo();
  const navigate = useNavigate();
  const rootPath = useRootPath();
  const { getVehicles } = useVehiclesAPI();
  const [environmentChangeLoading, setEnvironmentChangeLoading] =
    useRecoilState(environmentChangeLoadingAtom);
  const { clearAllNotification } = useNotification();
  const setVehiclesDriven = useSetRecoilState(vehiclesDrivenAtom);
  const setIsAuthError = useSetRecoilState(isAuthErrorAtom);
  const resetLaneletsCenterLinePoints = useResetRecoilState(
    laneletsCenterLinePointsAtom,
  );
  const {
    createVehiclesTelemetrySocket,
    closeVehiclesTelemetrySocket,
    createVehiclesMetadataSocket,
    closeVehiclesMetadataSocket,
  } = useVehiclesDataWebSocket();

  const savedEnvironment = useMemo(() => {
    const saved = store.local.get('environment');
    if (
      !isNullOrUndefined(saved) &&
      !isNullOrUndefined(saved.project) &&
      !isNullOrUndefined(saved.environment)
    ) {
      return {
        project: saved.project as Project,
        environment: saved.environment as Environment,
      };
    }
    return null;
  }, []);

  const handleInitialSelect = useCallback(() => {
    setInitialSelected(true);
  }, []);

  useMount(() => {
    // GA
    if (enableGA) {
      ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID);
    }

    AuthenticationManager.userManager.events.addSilentRenewError(() => {
      console.log('silent renew error');
      setIsAuthError(true);
    });

    const { search, pathname, hash } = window.location;
    const hasParamCode = search.includes('code=');
    const hasParamState = search.includes('state=');
    const hasParamScope = search.includes('scope=');
    const savedPathName: string = store.session.get('pathname');
    if (!hasParamCode && !hasParamState && !hasParamScope) {
      store.session.set('pathname', `${pathname}${search}${hash}`);
    } else {
      if (!savedPathName) {
        store.session.set('pathname', '/');
      }
    }
  });

  useEffect(() => {
    // GA
    if (enableGA) {
      ReactGA.send({
        hitType: 'pageview',
        page: `${location.pathname}${location.search}${location.hash}`,
      });
    }
  }, [location.pathname, location.search, location.hash]);

  /**
   * 走行環境が変更され、スコープが更新された場合
   */
  useEffect(() => {
    // Timezone 取得
    const updateTimezone = async () => {
      const res = await getTimezone();
      setTimezone(res);
      Config.defaultZone = res.timezone;
    };

    // エリアマップバージョン一覧取得
    const updateEnvironmentAreaMapVersions = async () => {
      // 走行環境内のエリアマップ取得権限がある場合、エリアマップバージョン一覧を取得
      const areaMapVersionRes = await getEnvironmentAreaMapVersions();
      setAreaMapVersions(areaMapVersionRes);
    };

    // 走行環境オプション
    const updateEnvironmentOption = async () => {
      const res = await getWebAutoApplicationEnvironmentOption();
      if (!isNullOrUndefined(res)) {
        setEnvironmentOption(res);
      }
      console.log('complete: get environment option');
    };

    // 停車地点取得
    const updatePlaces = async () => {
      const res = await getPlaces();
      setPlaces(res);
      console.log('complete: get places');
    };

    // 天気予報情報取得
    const updateWeatherLocations = async () => {
      const res = await getWeatherLocations();
      if (res.length > 0) {
        setWeatherLocation(res[0]);
        await getWeatherInfo(res[0].location_id);
        console.log('complete: get weather');
      } else {
        setWeatherLocation(null);
        setWeatherForecasts([]);
      }
    };

    /**
     * FMS 車両一覧情報取得
     */
    const updateVehicles = async () => {
      const res = await getVehicles();
      setVehicles(res);
      await createVehiclesTelemetrySocket();
      await createVehiclesMetadataSocket();
      console.log('complete: get vehicles');
    };

    /**
     * データ基盤車両一覧取得
     */
    const updateVehiclesDriven = async () => {
      const res = await getVehiclesDriven();
      setVehiclesDriven(res);
      console.log('complete: get datahub vehicles');
    };

    if (
      prevAssert?.scope !== assert.scope &&
      !isNullOrUndefined(assert.scope) &&
      assert.asserted
    ) {
      // Serial Request
      Promise.all([
        updateTimezone(),
        updateEnvironmentOption(),
        updateEnvironmentAreaMapVersions(),
        updatePlaces(),
        updateVehicles(),
      ])
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          console.groupEnd();
          // 全てのリクエスト完了後、ローディング画面終了
          setEnvironmentChangeLoading(false);

          // Parallel Request
          updateWeatherLocations();
          updateVehiclesDriven();
        });
    }
  }, [
    prevAssert,
    assert,
    getPlaces,
    getWebAutoApplicationEnvironmentOption,
    setEnvironmentOption,
    setPlaces,
    setAreaMapVersions,
    getWeatherLocations,
    setWeatherLocation,
    setWeatherForecasts,
    getWeatherInfo,
    getVehicles,
    setVehicles,
    getTimezone,
    setTimezone,
    getVehiclesDriven,
    setVehiclesDriven,
    createVehiclesTelemetrySocket,
    createVehiclesMetadataSocket,
    getEnvironmentAreaMapVersions,
    setEnvironmentChangeLoading,
  ]);

  /**
   * 走行環境が選択されている状態で、違う走行環境に変更された場合
   * サイトアクセス時には実行されない
   */
  const changeEnvironment = useCallback(() => {
    if (
      !isNullOrUndefined(prevEnvironment) &&
      !isNullOrUndefined(environment) &&
      prevEnvironment.environment_id !== environment.environment_id
    ) {
      store.local.remove('vehicle_id');
      // スナックバー全て削除
      clearAllNotification();
      // ローカルストレージクリア
      setVarianceStorage('vehicle_id', null);
      setVarianceStorage('area_map_version_id', null);
      setVarianceStorage('from_date', null);
      setVarianceStorage('to_date', null);
      setDrivingHistoryStorage('vehicle_id', null);
      setDrivingHistoryStorage('date', null);
      setDrivingHistoryStorage('from', null);
      setDrivingHistoryStorage('to', null);
      setAnalysisStorage('vehicle_id', null);
      setAnalysisStorage('date', null);
      setAnalysisStorage('from', null);
      setAnalysisStorage('to', null);
      setAnalysisStorage('condition', null);
      setAnalysisStorage('score_type', null);
      // 予実解析
      if (location.pathname.includes('plan-actual-variance')) {
        navigate(`${rootPath}plan-actual-variance/`);
      }
      // 走行履歴
      if (location.pathname.includes('driving-history')) {
        navigate(`${rootPath}driving-history/`);
      }
      // エラー一覧
      if (location.pathname.includes('error-detail')) {
        navigate(`${rootPath}error-detail/`);
      }
      // 分析
      if (location.pathname.includes('analysis')) {
        navigate(`${rootPath}analysis/?type=error`);
      }
      // 走行メモ・一覧
      if (location.pathname.includes('driving-note-management')) {
        navigate(`${rootPath}driving-note-management/`);
      }
      // 走行メモ・登録
      if (location.pathname.includes('driving-note-register')) {
        navigate(`${rootPath}driving-note-management/`);
      }
      // 走行メモ・詳細
      if (location.pathname.includes('driving-note-details')) {
        navigate(`${rootPath}driving-note-management/`);
      }
      // 運行レポートページ
      if (location.pathname.includes('operation-report')) {
        navigate(`${rootPath}operation-report/`);
      }
      // スケジュール一覧
      if (location.pathname.includes('schedule-check')) {
        navigate(`${rootPath}schedule-check/`);
      }
      // 車両一覧
      if (location.pathname.includes('vehicle-management')) {
        navigate(`${rootPath}vehicle-management/`);
      }
      // 通行禁止区域設定
      if (
        location.pathname.includes('/driving-environment/no-entry-zone-setting')
      ) {
        navigate(`${rootPath}driving-environment/`);
      }
    }
  }, [
    prevEnvironment,
    environment,
    navigate,
    location.pathname,
    rootPath,
    clearAllNotification,
  ]);

  /**
   * 走行環境が変更された場合（プロジェクト & 走行環境も含む）
   */
  useEffect(() => {
    const updateScope = async () => {
      setAssert({
        scope: '',
        featureScope: '',
        asserted: false,
      });

      const assertRes = await postAssert();
      if (assertRes) {
        setAssert({
          scope: assertRes.scope,
          featureScope: assertRes.feature_scope,
          asserted: true,
        });
      }
      await postAssertAreaMap();
      console.log('complete: post assertion');
    };
    // 走行環境が変更された場合（アクセス時含む）
    if (
      environment &&
      prevEnvironment?.environment_id !== environment.environment_id
    ) {
      // Websocketを閉じる
      closeVehiclesTelemetrySocket();
      closeVehiclesMetadataSocket();
      setVehicles([]);
      setVehiclesDriven([]);
      setWeatherLocation(null);
      setWeatherForecasts([]);
      resetLaneletsCenterLinePoints();
      setEnvironmentChangeLoading(true);
      console.group('change environment: api request start');
      updateScope();

      // パスの変更
      const pathNames = window.location.pathname.substring(1).split('/');
      const includeProjectEnvironmentPath = pathNames.splice(2).join('/');
      const params = new URLSearchParams(window.location.search);
      let paramsString =
        params.toString().length > 0 ? `?${params.toString()}` : '';
      if (paramsString.includes('?code=')) {
        paramsString = '';
      }
      const currentPath = `${window.location.pathname}${window.location.search}`;
      const targetPath = `/${environment.project_id}/${environment.environment_id}/${includeProjectEnvironmentPath}${paramsString}`;
      if (currentPath !== targetPath) {
        new Promise((resolve) => {
          navigate(targetPath);
          resolve('resolved');
        }).then(() => {
          changeEnvironment();
        });
      }
    }
  }, [
    changeEnvironment,
    closeVehiclesTelemetrySocket,
    closeVehiclesMetadataSocket,
    environment,
    navigate,
    postAssert,
    postAssertAreaMap,
    prevEnvironment?.environment_id,
    setAssert,
    setEnvironmentChangeLoading,
    setVehicles,
    setVehiclesDriven,
    setWeatherForecasts,
    setWeatherLocation,
    resetLaneletsCenterLinePoints,
  ]);

  useEffect(() => {
    const checkEnvironment = async () => {
      // Project & Environment 選択済み
      if (initialSelected) return;
      // User が存在しない場合
      if (isNullOrUndefined(user)) return;

      const savedPathName: string = store.session.get('pathname');
      if (isNullOrUndefined(savedPathName)) return;
      const savedPathNames = savedPathName.substring(1).split('/');
      const pathProjectId = savedPathNames[0];
      const pathEnvironmentId = savedPathNames[1];

      // LocalStorage or URL に保存された Project & Environment が存在しない場合
      if (
        isNullOrUndefined(savedEnvironment) &&
        isNullOrUndefined(pathProjectId) &&
        isNullOrUndefined(pathEnvironmentId)
      ) {
        setSavedDataValidState('invalid');
        return;
      }
      let targetProjectId = pathProjectId;
      // ルートパスかつ、保存されている ProjectID が参照できる場合
      if (
        savedPathName === '/' &&
        targetProjectId.length === 0 &&
        !isNullOrUndefined(savedEnvironment)
      ) {
        targetProjectId = savedEnvironment.project.id;
      }
      // Project一覧取得
      const projects = await getProjects();
      setProjects(projects);
      // 保存されているProject IDが取得したProject一覧に含まれるかどうか
      const includedProject = projects.find(
        (project) => project.id === targetProjectId,
      );
      if (isNullOrUndefined(includedProject)) {
        // 含まれていない場合ダイアログ表示
        setSavedDataValidState('invalid');
        navigate('/', { replace: true });
        return;
      }
      let targetEnvironmentId = pathEnvironmentId;
      // ルートパスかつ、保存されている Environment ID が参照できる場合
      if (
        savedPathName === '/' &&
        (isNullOrUndefined(targetEnvironmentId) ||
          targetEnvironmentId.length === 0) &&
        !isNullOrUndefined(savedEnvironment)
      ) {
        targetEnvironmentId = savedEnvironment.environment.environment_id;
      }
      // 保存されたProject IDでEnvironment一覧取得
      const environments = await getEnvironments(targetProjectId);
      setEnvironments(environments);
      // 保存されているEnvironment IDが取得したEnvironment一覧に含まれるかどうか
      const includedEnvironment = environments.find(
        (environment) => environment.environment_id === targetEnvironmentId,
      );
      if (isNullOrUndefined(includedEnvironment)) {
        // 含まれていない場合ダイアログ表示
        setSavedDataValidState('invalid');
        navigate('/', { replace: true });
        return;
      }
      const rootPathName = `/${includedProject.id}/${includedEnvironment.environment_id}/`;
      // 保存されていた情報が問題なければ各値をセット
      if (!isNullOrUndefined(savedPathName)) {
        if (!savedPathName.includes(rootPathName)) {
          navigate(rootPathName, { replace: true });
        } else {
          navigate(savedPathName, { replace: true });
          store.session.remove('pathname');
        }
      } else {
        navigate(rootPathName, { replace: true });
      }
      setProject(includedProject);
      setEnvironment(includedEnvironment);
      setInitialSelected(true);
      setSavedDataValidState('valid');
    };
    checkEnvironment();
  }, [
    user,
    setProject,
    setProjects,
    setEnvironment,
    setEnvironments,
    initialSelected,
    getProjects,
    getEnvironments,
    navigate,
    savedEnvironment,
  ]);

  /**
   * プロジェクト変更時
   */
  useEffect(() => {
    (async () => {
      if (!isNullOrUndefined(project) && prevProject?.id !== project.id) {
        const membersRes = await getProjectMembers(project.id);
        setProjectMembers(membersRes);
      }
    })();
  }, [prevProject, project, getProjectMembers, setProjectMembers]);

  if (oidcErrorDescription()) {
    return (
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        {oidcErrorDescription()}
      </Box>
    );
  }

  if (!initialSelected) {
    // Project & Environment が選択されていない場合

    if (!isNullOrUndefined(user) && savedDataValidState === 'invalid') {
      // Userが存在 && 保存されたProject & Environmentが存在しない or 不正な場合ダイアログ表示
      return (
        <ProjectEnvironmentDialog
          open
          onInitialSelected={handleInitialSelect}
        />
      );
    }

    return (
      <>
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
        <AuthErrorDialog />
        <ErrorBoundary FallbackComponent={GlobalErrorDialog}>
          <Routes>
            <Route path="*" element={<SigninCallback />} />
          </Routes>
        </ErrorBoundary>
      </>
    );
  }

  if (environmentChangeLoading) {
    return (
      <>
        <ProgressWrapper>
          <CircularProgress />
        </ProgressWrapper>
        <AuthErrorDialog />
      </>
    );
  }

  return (
    <Layout>
      <EnvironmentRoutes />
      <VehicleAlertNotification />
      <WeatherNotification />
      <AuthErrorDialog />
      <WebsocketErrorDialog />
    </Layout>
  );
};

export default Routing;

const ProgressWrapper = styled(Paper)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
