import { AuthenticationManager } from '@api/auth';
import { userAtom } from '@data/auth';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover as MuiPopover,
  Typography,
  styled,
} from '@mui/material';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import React, { useCallback, useState } from 'react';
import { User } from 'react-feather';
import { useRecoilValue } from 'recoil';

const HeaderUserMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const user = useRecoilValue(userAtom);

  const toggleMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const closeMenu = useCallback(async () => {
    setAnchorEl(null);
  }, []);

  const handleClickLogout = useCallback(async () => {
    try {
      AuthenticationManager.userManager.signOutRedirect();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <>
      <IconButton
        aria-owns={!isNullOrUndefined(anchorEl) ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
        data-testid="userInfo-icon"
      >
        <User />
      </IconButton>
      <Popover
        id="menu-appbar"
        open={!isNullOrUndefined(anchorEl)}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={4}>
          <Typography variant="caption" color="textSecondary">
            User ID
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {user?.profile.sub}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            User Name
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            {`${user?.profile.given_name} ${user?.profile.family_name}`}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Email
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {user?.profile.email}
          </Typography>
        </Box>
        <Divider />
        <Box p={2}>
          <Button onClick={handleClickLogout}>Logout</Button>
        </Box>
      </Popover>
    </>
  );
};

export default HeaderUserMenu;

const Popover = styled(MuiPopover)`
  .MuiPopover-paper {
    text-align: right;
    .MuiButton-root {
      text-transform: none;
      text-align: left;
    }
  }
`;
