import type { SelectChangeEvent, MenuListProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import type { SpacingProps } from '@mui/system';
import { useConcatWord } from '@utils/i18n';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import * as vehicleUtil from '@data/fms/vehicle/utils';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import type { Vehicle } from '@data/fms/vehicle/types';
import { allVehiclesSelector } from '@data/fms/vehicle/states';
import { useStoredVehicleId } from '@data/fms/vehicle/hooks';

type Props = SpacingProps & {
  size?: 'small' | 'medium';
  vehicleId: string;
  isAll?: boolean;
  isScheduleRegister?: boolean;
  disabled?: boolean;
  width?: string;
  onChange: (selectedVehicle: Vehicle | Vehicle[]) => void;
};

const VehicleSelect: React.FC<Props> = ({
  size = 'medium',
  vehicleId,
  isAll = false,
  disabled = false,
  isScheduleRegister = false,
  width = '200px',
  onChange,
  ...rest
}: Props) => {
  const allVehicles = useRecoilValue(allVehiclesSelector);
  const { setStoredVehicleId } = useStoredVehicleId();
  const { t } = useTranslation();
  const { concat } = useConcatWord();

  const filteredVehicles = useMemo(() => {
    let filtered = allVehicles;
    if (isScheduleRegister) {
      filtered = filtered.filter((vehicle) =>
        vehicleUtil.isScheduleRegister(vehicle),
      );
    }
    return filtered;
  }, [allVehicles, isScheduleRegister]);

  const handleChangeSelect = useCallback(
    (e: SelectChangeEvent<string>) => {
      if (e.target.value === 'all') {
        onChange(filteredVehicles);
        setStoredVehicleId(undefined);
        return;
      }
      const selectedVehicle = filteredVehicles.find(
        (vehicle) => vehicle.vehicle_id === e.target.value,
      );
      if (isNullOrUndefined(selectedVehicle)) return;
      setStoredVehicleId(selectedVehicle.vehicle_id);
      onChange(selectedVehicle);
    },
    [filteredVehicles, onChange, setStoredVehicleId],
  );

  const hasVehicleId = useMemo(() => {
    return filteredVehicles.some((vehicle) => vehicle.vehicle_id === vehicleId);
  }, [vehicleId, filteredVehicles]);

  const menuItem = useMemo(() => {
    const arr = [];
    if (isAll) {
      arr.push(
        <MenuItem key="all" value="all" data-testid="vehicleSelect-list-all">
          {t('vehicle.all')}
        </MenuItem>,
      );
    }
    return [
      ...arr,
      filteredVehicles.map((vehicle) => (
        <MenuItem
          value={vehicle.vehicle_id}
          key={vehicle.vehicle_id}
          data-testid={`vehicleSelect-list-${vehicle.vehicle_id}`}
        >
          {vehicle.vehicle_name}
        </MenuItem>
      )),
    ];
  }, [isAll, filteredVehicles, t]);

  const selecteValue = useMemo(() => {
    if (isAll && vehicleId === 'all') return vehicleId;
    return hasVehicleId ? vehicleId : '';
  }, [hasVehicleId, isAll, vehicleId]);

  const label = useMemo(
    () => concat([t('vehicle.vehicle'), t('common.action.select')], true),
    [t, concat],
  );

  return (
    <FormControl
      size={size}
      disabled={disabled}
      sx={{ width }}
      {...rest}
      data-testid="vehicleSelect-pulldown"
    >
      <InputLabel>{label}</InputLabel>
      <Select
        size={size}
        label={label}
        value={selecteValue}
        onChange={handleChangeSelect}
        MenuProps={{
          MenuListProps: {
            'data-testid': 'vehicleSelect-list',
          } as Partial<MenuListProps<'ul'>>,
        }}
      >
        {menuItem}
      </Select>
    </FormControl>
  );
};

export default React.memo(VehicleSelect);
