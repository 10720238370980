import React, { useEffect, useState, useCallback, useMemo } from 'react';
import type { VisualizeEngine } from '@tier4/autowareviz-core';
import { AutowareViz, withAutowareViz } from '@tier4/autowareviz-react';
import {
  Coordinate,
  GroundGrid,
  Space as ThreeSpace,
  Model as VehicleObject,
  Camera,
  CamMode,
  DetectedObjects,
  Path,
  Route,
  Trajectory,
  VirtualWall,
  TrackingObjects,
  PredictedPath,
} from '@tier4/autowareviz-react-threejs';
import { useViz } from '@api/viz';
import {
  MapContainer,
  NoEntryZonePolylines,
  StoppableMarkers,
} from '@components/common/molecules';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import {
  ActiveScheduleRoutePolyline,
  FeatureToggle,
  RouteOriginDestinationMarker,
  ToggleOn,
  VehicleMarker,
} from '@components/common/atoms';
import { Box, CircularProgress, styled } from '@mui/material';
import milee from '/assets/milee.gltf';
import MapMenu from '@components/common/molecules/MapMenu';
import type { Vehicle } from '@data/fms/vehicle/types';
import { useActiveScheduleAndRoute } from '@data/fms/route/hooks';
import LaneletPolylines from '@components/common/molecules/LaneletPolylines';
import { SCOPES } from '@data/auth';

type VisualizationProps = {
  engine: VisualizeEngine;
};

const Space = withAutowareViz(ThreeSpace);

const Visualization: React.FC<VisualizationProps> = React.memo(
  ({ engine }: VisualizationProps) => {
    return (
      <AutowareViz engine={engine}>
        <Space dimension={3} initialCameraPosition={[-10, 0, 20]}>
          <ambientLight />
          {/* eslint-disable-next-line react/no-unknown-property */}
          <pointLight position={[100, 100, 100]} />
          <GroundGrid width={200000} height={200000} />
          <Coordinate frameId="map">
            <Coordinate frameId="base_link">
              <VehicleObject
                url={milee as unknown as string}
                rotateToAutoware
              />
              <Camera
                camMode={CamMode.tracking}
                trackingCameraPosition={[-10, 0, 5]}
                trackingTargetPosition={[5, 0, 0]}
                trackingDelay={100}
              />
              <DetectedObjects />
            </Coordinate>
            <Path />
            <Route />
            <Trajectory />
            <VirtualWall />
            <TrackingObjects />
            <PredictedPath />
          </Coordinate>
        </Space>
      </AutowareViz>
    );
  },
);

Visualization.displayName = 'Visualization';

type VizContainerProps = {
  ws: WebSocket;
  vehicle: Vehicle;
  projectId: string;
};

const VizContainer: React.FC<VizContainerProps> = ({
  ws,
  vehicle,
  projectId,
}: VizContainerProps) => {
  const engine = useViz(ws, vehicle.vehicle_id, projectId);

  if (isNullOrUndefined(engine)) return null;
  return <Visualization engine={engine} />;
};

type Props = {
  ws: WebSocket | null;
  vehicle: Vehicle;
  projectId: string;
  displayViz: boolean;
  isActiveViz: boolean;
  isAutowareLaunced: boolean;
};

const RemoteViz: React.FC<Props> = ({
  ws,
  vehicle,
  projectId,
  displayViz,
  isActiveViz,
  isAutowareLaunced,
}: Props) => {
  const { sortedTaskRoutesForPolylines, originPoint, destinationPoint } =
    useActiveScheduleAndRoute(vehicle);
  const [map, setMap] = useState<google.maps.Map>();

  const showAutowareViz = useMemo(
    () => isAutowareLaunced && isActiveViz,
    [isAutowareLaunced, isActiveViz],
  );

  const mapWrapperWidth = useMemo(
    () => (showAutowareViz && displayViz ? '50%' : '100%'),
    [showAutowareViz, displayViz],
  );

  const handleLoadMap = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(map)) return;
    map.setCenter(vehicle.telemetry.location);
  }, [vehicle.telemetry.location, map]);

  return (
    <Wrapper>
      <MapWrapper
        sx={{
          width: mapWrapperWidth,
        }}
      >
        <MapContainer onLoad={handleLoadMap}>
          <VehicleMarker vehicle={vehicle} isDetail />
          <MapMenu />
          <FeatureToggle scope={SCOPES.DescribeLaneletsCenterLinePoints}>
            <ToggleOn>
              <LaneletPolylines />
            </ToggleOn>
          </FeatureToggle>
          <StoppableMarkers />
          <NoEntryZonePolylines />
          {sortedTaskRoutesForPolylines.map((taskRoute, i) => (
            <ActiveScheduleRoutePolyline key={i} taskRoute={taskRoute} />
          ))}
          {!isNullOrUndefined(originPoint) && (
            <RouteOriginDestinationMarker position={originPoint.location} />
          )}
          {!isNullOrUndefined(destinationPoint) && (
            <RouteOriginDestinationMarker
              position={destinationPoint.location}
            />
          )}
        </MapContainer>
      </MapWrapper>
      {showAutowareViz && (
        <VizWrapper sx={{ display: displayViz ? 'block' : 'none' }}>
          {!isNullOrUndefined(ws) ? (
            <VizContainer ws={ws} vehicle={vehicle} projectId={projectId} />
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
        </VizWrapper>
      )}
    </Wrapper>
  );
};

export default React.memo(RemoteViz);

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;
  display: flex;
`;

const MapWrapper = styled('div')`
  height: 100%;
  position: relative;

  .gmnoprint {
    &[role='menubar'] {
      margin-left: 80px !important;
    }
  }
`;

const VizWrapper = styled('div')`
  width: 50%;
  height: 100%;
  position: relative;
  color: white;
`;
