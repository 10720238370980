import type { APIState, Result } from '@api';
import { Failure, Success, useFMSAPI, useErrorMessage } from '@api';
import { projectAtom, SCOPES, useHasScope } from '@data/auth';
import { environmentAtom } from '@data/fms/environment/states';
import type { Route } from '@data/fms/route/types';
import { useNotification } from '@data/notification';
import isNullOrUndefined, { notNull } from '@utils/isNullOrUndefined';
import type { AxiosResponse, AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilCallback } from 'recoil';

/**
 * 走行ルート取得API
 */
export const useRouteAPI = (): {
  state: APIState;
  getRoute: (routeIds: string[]) => Promise<Route[]>;
} => {
  const [state, setState] = useState<APIState>('none');
  const { notifyError } = useNotification();
  const fmsAPI = useFMSAPI();
  const getHasScope = useHasScope();
  const getErrorMessage = useErrorMessage();
  const { t } = useTranslation();

  const getRouteAPI = useCallback(
    async (
      projectId: string,
      environmentId: string,
      routeId: string,
    ): Promise<Result<Route, AxiosResponse>> => {
      try {
        const res = await fmsAPI.get(
          `/${projectId}/environments/${environmentId}/routes/${routeId}`,
        );
        return new Success(res?.data);
      } catch (error) {
        return new Failure((error as AxiosError).response as AxiosResponse);
      }
    },
    [fmsAPI],
  );

  const getRoute = useRecoilCallback(
    ({ snapshot }) =>
      async (routeIds: string[]) => {
        setState('loading');
        if (!getHasScope(SCOPES.DescribeRoute)) {
          setState('hasError');
          return [];
        }
        const project = await snapshot.getPromise(projectAtom);
        const environment = await snapshot.getPromise(environmentAtom);
        if (isNullOrUndefined(project) || isNullOrUndefined(environment)) {
          notifyError({
            message: t('api.fms.get_route', { status: 'failed' }),
          });
          setState('hasError');
          return [];
        }
        const responses = await Promise.all(
          routeIds.map(async (routeId: string) => {
            const res = await getRouteAPI(
              project!.id,
              environment!.environment_id,
              routeId,
            );
            if (isNullOrUndefined(res.value) || res.isFailure()) {
              notifyError({
                message: `${t('api.fms.get_route', {
                  status: 'failed',
                })}: ${getErrorMessage(res)}`,
              });
              return null;
            }
            return res.value;
          }),
        );
        setState('hasValue');
        const filtered = responses.filter(notNull);
        return filtered;
      },
    [notifyError, getRouteAPI, getHasScope, t, getErrorMessage],
  );

  return {
    state,
    getRoute,
  };
};
