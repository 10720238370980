import React from 'react';
import { Box, CircularProgress, styled } from '@mui/material';

const Root = styled(Box)`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

const Loader: React.FC = () => (
  <Root>
    <CircularProgress color="secondary" />
  </Root>
);

export default Loader;
