import {
  isCurrentlyAppliedNoEntryZone,
  isPeriod,
} from '@data/fms/noEntryZone/states';
import type { NoEntryZoneWithLanelets } from '@data/fms/noEntryZone/type';
import { en } from '@data/i18n/locales';
import styled from '@emotion/styled';
import { Box, Chip, colors, Grid, IconButton, Typography } from '@mui/material';
import { InfoBox, Polyline } from '@react-google-maps/api';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { Block, Close } from '@mui/icons-material';
import { mapMenuAtom } from '@data/ui/states';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { appliedNoEntryZoneSettingAtom } from '@data/fms/areamap/states';
import type { LatLng } from '@data/fms/place/types';
import type { TimetableDayOfTheWeek } from '@data/fms/schedule/types';

type NoEntryZonePolylineProps = {
  zone: NoEntryZoneWithLanelets;
  path: LatLng[];
  display: boolean;
  zIndex: number;
  onClick: (zone: NoEntryZoneWithLanelets, latlng: google.maps.LatLng) => void;
};

const NoEntryZonePolyline: React.FC<NoEntryZonePolylineProps> = memo(
  ({ zone, path, zIndex, display, onClick }: NoEntryZonePolylineProps) => {
    const isExpired = useMemo(() => {
      if (!zone.end_time) return false;
      const endTime = DateTime.fromISO(zone.end_time).endOf('minute');
      const now = DateTime.now();
      return endTime < now;
    }, [zone]);

    const handleClickPolyline = useCallback(
      (e: google.maps.MapMouseEvent) => {
        const latlng = e.latLng;
        if (latlng) {
          onClick(zone, latlng);
        }
      },
      [onClick, zone],
    );

    if (isExpired || !display) return null;

    return (
      <>
        <Polyline
          path={path}
          options={{
            strokeOpacity: 0,
            strokeWeight: 7,
            clickable: true,
            zIndex: 500 + zIndex,
          }}
          onClick={handleClickPolyline}
        />
        <Polyline
          path={path}
          options={{
            strokeColor: isCurrentlyAppliedNoEntryZone(zone)
              ? colors.red[600]
              : colors.orange[600],
            strokeOpacity: 1.0,
            strokeWeight: 2,
            clickable: false,
            zIndex: 501 + zIndex,
          }}
        />
      </>
    );
  },
);

NoEntryZonePolyline.displayName = 'NoEntryZonePolyline';

const NoEntryZonePolylines: React.FC = () => {
  const appliedNoEntryZoneSetting = useRecoilValue(
    appliedNoEntryZoneSettingAtom,
  );
  const { t, i18n } = useTranslation();
  const [openZone, setOpenZone] = useState<{
    zone: NoEntryZoneWithLanelets;
    latlng: google.maps.LatLng;
  } | null>(null);
  const mapMenu = useRecoilValue(mapMenuAtom);

  const time = useMemo(() => {
    if (!openZone) return null;
    const { zone } = openZone;
    if (isPeriod(zone)) {
      return (
        <>
          <Box mb={2}>
            <Grid container justifyContent="space-between">
              {Object.keys(en.common.day_of_week.original).map((day) => {
                const isSelected = zone.days_of_the_week!.includes(
                  day as TimetableDayOfTheWeek,
                );
                return (
                  <DayChip
                    size="small"
                    color={isSelected ? 'primary' : 'default'}
                    key={day}
                    label={t(`common.day_of_week.short.${day}`)}
                  />
                );
              })}
            </Grid>
          </Box>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              {DateTime.fromFormat(zone.start_hm!, 'HH:mm').toFormat('HH:mm')}
            </Grid>
            <Grid item>-</Grid>
            <Grid item>
              {DateTime.fromFormat(zone.end_hm!, 'HH:mm').toFormat('HH:mm')}
            </Grid>
          </Grid>
        </>
      );
    }
    const startTime = DateTime.fromISO(zone.start_time!);
    const endTime = DateTime.fromISO(zone.end_time!);
    const isSameYear = startTime.hasSame(endTime, 'year');
    return (
      <Grid container alignItems="center" spacing={2}>
        <Grid item>{startTime.toFormat('yyyy/MM/dd HH:mm')}</Grid>
        <Grid item>-</Grid>
        <Grid item>
          {endTime.toFormat(`${isSameYear ? '' : 'yyyy/'}MM/dd HH:mm`)}
        </Grid>
      </Grid>
    );
  }, [openZone, t]);

  const handleClickPolyline = useCallback(
    (zone: NoEntryZoneWithLanelets, latlng: google.maps.LatLng) => {
      setOpenZone({
        zone,
        latlng,
      });
    },
    [],
  );

  const handleClose = useCallback(() => {
    setOpenZone(null);
  }, []);

  const infoSize = useMemo(
    () => (i18n.language === 'ja' ? 220 : 240),
    [i18n.language],
  );

  if (!appliedNoEntryZoneSetting) return null;

  return (
    <>
      {!isNullOrUndefined(openZone) &&
        mapMenu.noEntryZones[openZone.zone.no_entry_zone_id] && (
          <InfoBox
            position={openZone.latlng}
            options={{
              zIndex: 50,
              disableAutoPan: true,
              alignBottom: true,
              closeBoxURL: '',
              pixelOffset: new window.google.maps.Size(-infoSize * 0.5, -7),
            }}
          >
            <Box>
              <Wrapper size={infoSize} p={2}>
                <Box mb={1}>
                  <TitleLabel color={colors.grey[400]}>
                    {t(
                      'driving_environment.no_entry_zone_setting.no_entry_zone.title',
                    )}
                  </TitleLabel>
                </Box>
                <Box mb={2}>
                  <Typography>{openZone.zone.name}</Typography>
                </Box>
                <Box>{time}</Box>
                {isCurrentlyAppliedNoEntryZone(openZone.zone) && (
                  <CurrentLabel>
                    <Grid container justifyContent="center" alignItems="center">
                      <Grid item sx={{ width: 14, height: 14, mr: 1 }}>
                        <Block
                          fontSize="small"
                          sx={{ width: 14, height: 14 }}
                        />
                      </Grid>
                      <Grid item>
                        {t(
                          'driving_environment.no_entry_zone_setting.map.currently_applied_no_entry_zone',
                        )}
                      </Grid>
                    </Grid>
                  </CurrentLabel>
                )}
                <IconButton
                  size="small"
                  onClick={handleClose}
                  sx={{
                    transform: 'scale(0.6)',
                    transformOrigin: 'top right',
                    position: 'absolute',
                    top: 4,
                    right: 4,
                    color: 'white',
                  }}
                >
                  <Close />
                </IconButton>
              </Wrapper>
            </Box>
          </InfoBox>
        )}
      {appliedNoEntryZoneSetting.no_entry_zones.map((zone) =>
        Object.keys(zone.lanelets).map((key, i) => {
          const isActive = isCurrentlyAppliedNoEntryZone(zone);
          const zIndex = isActive
            ? appliedNoEntryZoneSetting.no_entry_zones.length + 1
            : i;
          return (
            <NoEntryZonePolyline
              key={`${zone.no_entry_zone_id}_${i}`}
              zone={zone}
              zIndex={zIndex}
              path={zone.lanelets[key]}
              display={mapMenu.noEntryZones[zone.no_entry_zone_id]}
              onClick={handleClickPolyline}
            />
          );
        }),
      )}
    </>
  );
};

export default NoEntryZonePolylines;

type WrapperProps = {
  size: number;
};

const Wrapper = styled(Box)<WrapperProps>`
  width: ${({ size }) => `${size}px`};
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  line-height: 1;
  color: white;

  p {
    margin: 0;
    font-size: 12px;

    span {
      font-weight: bold;
      color: white;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: calc(100% - 1px);
    left: 50%;
    margin-left: -7px;
    border: 7px solid transparent;
    border-top: 7px solid rgba(0, 0, 0, 0.8);
  }
`;

const DayChip = styled(Chip)`
  font-size: 10px;
  height: 18px;

  &.MuiChip-filledDefault {
    background-color: white;
  }

  span {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const TitleLabel = styled(Typography)`
  color: ${colors.grey[400]};
`;

const CurrentLabel = styled(Box)`
  margin-top: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.error.main};
  border-radius: 4px;
  padding: 4px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.06em;
`;
