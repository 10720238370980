import { atom, selector } from 'recoil';
import type { LaneletsCenterLinePoints, Lanes } from './types';

export const laneletsCenterLinePointsLoadingAtom = atom({
  key: 'laneletsCenterLinePointsLoadingAtom',
  default: false,
});

/**
 * APIから取得したレーン情報
 */
export const laneletsCenterLinePointsAtom = atom<LaneletsCenterLinePoints>({
  key: 'laneletsCenterLinePointsAtom',
  default: {
    area_map_version_id: '',
    lanelets_center_line_points: [],
  },
});

/**
 * APIから取得した配列をレーンIDを key にした Lanes オブジェクトに変換して返す
 */
export const laneletsSelector = selector<Lanes>({
  key: 'laneletsSelector',
  get: ({ get }) => {
    const laneletsCenterLinePoints = get(laneletsCenterLinePointsAtom);
    const lanes: Lanes = {};
    laneletsCenterLinePoints.lanelets_center_line_points.forEach((data, i) => {
      const prevLaneId =
        laneletsCenterLinePoints.lanelets_center_line_points[i - 1]?.lanelet_id;
      const laneId = data.lanelet_id;
      if (prevLaneId !== laneId) {
        lanes[laneId] = [];
      }
      lanes[laneId].push({
        lat: data.location.lat,
        lng: data.location.lng,
      });
    });
    return lanes;
  },
});
