import type { PartialRouteSearchRequestParams } from '@api/fms';
import {
  usePartialRouteSearchAPI,
  usePartialRouteSearchFromCurrentPositionAPI,
  useRouteAPI,
  useRouteSearchAPI,
  useRouteSearchFromCurrentPositionAPI,
  useScheduleAPI,
  useSchedulesAPI,
  useFMSWebSocket,
} from '@api/fms';
import { useSettings } from '@data/settings/hooks';
import {
  editBasicPlacesAtom,
  editLoopPlacesAtom,
  editPlacesSelector,
  editWeekTimetableAtom,
  isTimetableStartTimeAdjustedAtom,
  searchedRoutesAtom,
  selectedDayOfWeekAtom,
  selectedDayOfWeekTimetableAtom,
  selectedScheduleTypeAtom,
  selectedViaPointAtom,
  validTimetableStartTimesSelector,
} from '@pages/ScheduleRegister/states';
import type {
  BasicPlace,
  SearchedPartialRoutes,
  SearchedRoute,
  SearchedRoutes,
  TimetablePlaceData,
} from '@pages/ScheduleRegister/types';
import isNullOrUndefined, { notNull } from '@utils/isNullOrUndefined';
import {
  isConnected,
  isDisconnected,
  isShutdown,
} from '@data/fms/vehicle/utils';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious, useUnmount } from 'react-use';
import {
  useRecoilCallback,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';
import type { Place } from '../place/types';
import type { Schedule } from '../schedule/types';
import type { Vehicle } from '../vehicle/types';
import type { Route, TaskRoute } from './types';
import { en } from '@data/i18n/locales';
import { taskRouteSortOrder } from './constants';

export const useActiveScheduleAndRoute = (
  vehicle: Vehicle | null | undefined,
) => {
  const prevVehicle = usePrevious(vehicle);
  const {
    createSocket: updateVehicleActiveSchedule,
    data: updatedVehicleActiveSchedule,
    closeSocket: closeVehicleActiveScheduleSocket,
  } = useFMSWebSocket({ channel: 'vehicleActiveSchedule' });
  const { getRoute } = useRouteAPI();
  const { settings } = useSettings();
  const [activeSchedule, setActiveSchedule] = useState<Schedule | null>(null);
  const [routes, setRoutes] = useState<(TaskRoute | null)[]>([]);
  const [originPoint, setOriginPoint] = useState<Place | null>(null);
  const [destinationPoint, setDestinationPoint] = useState<Place | null>(null);

  const getActiveScheduleRoute = useCallback(async () => {
    if (
      isNullOrUndefined(vehicle) ||
      isConnected(vehicle) ||
      isDisconnected(vehicle) ||
      isShutdown(vehicle)
    ) {
      setActiveSchedule(null);
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
      return;
    }

    try {
      setActiveSchedule(null);
      setRoutes([]);
      updateVehicleActiveSchedule(vehicle.vehicle_id);
    } catch {
      setActiveSchedule(null);
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
    }

    if (!settings.vehicle.displayRoute) {
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
    }
  }, [settings.vehicle.displayRoute, vehicle, updateVehicleActiveSchedule]);

  const resetScheduleAndRoute = useCallback(
    (doVehicleChange?: boolean) => {
      closeVehicleActiveScheduleSocket(doVehicleChange);
      setRoutes([]);
      setActiveSchedule(null);
      setOriginPoint(null);
      setDestinationPoint(null);
    },
    [closeVehicleActiveScheduleSocket],
  );

  const sortedTaskRoutesForPolylines = useMemo(() => {
    const filtered = routes.filter(notNull);
    const sorted = filtered.sort(
      (a, b) =>
        taskRouteSortOrder.indexOf(a.status) -
        taskRouteSortOrder.indexOf(b.status),
    );
    // NOTE: 配列の後から上にくるため reverse する
    return sorted.reverse();
  }, [routes]);

  useEffect(() => {
    if (
      isNullOrUndefined(activeSchedule) ||
      activeSchedule.status === 'canceled' ||
      activeSchedule.status === 'aborted' ||
      activeSchedule.status === 'disabled' ||
      activeSchedule.status === 'done' ||
      isNullOrUndefined(activeSchedule.tasks)
    ) {
      setRoutes([]);
      setActiveSchedule(null);
      setOriginPoint(null);
      setDestinationPoint(null);
    }
  }, [activeSchedule]);

  useEffect(() => {
    if (settings.vehicle.displayRoute && activeSchedule?.status === 'doing') {
      (async () => {
        if (isNullOrUndefined(activeSchedule.tasks)) return;
        const routes = await Promise.all(
          activeSchedule.tasks.map(async (task) => {
            if (
              isNullOrUndefined(task.route_ids) ||
              task.route_ids.length === 0
            )
              return null;
            const routeRes = await getRoute(task.route_ids);
            return {
              status: task.status,
              routes: routeRes,
            };
          }),
        );

        const originRoute = routes[0];
        const originRouteFirst = originRoute?.routes[0];
        const destinationRoute = routes[routes.length - 1];
        const destinationRouteFirst = destinationRoute?.routes[0];

        setRoutes(routes);
        setOriginPoint(originRouteFirst?.points[0] ?? null);
        setDestinationPoint(
          !isNullOrUndefined(destinationRouteFirst)
            ? destinationRouteFirst.points[
                destinationRouteFirst.points.length - 1
              ]
            : null,
        );
      })();
    } else {
      setRoutes([]);
      setOriginPoint(null);
      setDestinationPoint(null);
    }
  }, [activeSchedule, getRoute, settings.vehicle.displayRoute]);

  useEffect(() => {
    if (
      (isNullOrUndefined(prevVehicle) && !isNullOrUndefined(vehicle)) ||
      (prevVehicle?.vehicle_id !== vehicle?.vehicle_id &&
        !isNullOrUndefined(vehicle))
    ) {
      resetScheduleAndRoute(true);
      // 選択車両変更のタイミングでリクエスト
      getActiveScheduleRoute();
    } else if (isNullOrUndefined(vehicle)) {
      // 車両未選択の場合はルートを表示しない
      resetScheduleAndRoute();
    }
  }, [
    vehicle,
    prevVehicle,
    getActiveScheduleRoute,
    closeVehicleActiveScheduleSocket,
    resetScheduleAndRoute,
  ]);

  useEffect(() => {
    setActiveSchedule(updatedVehicleActiveSchedule);
  }, [updatedVehicleActiveSchedule]);

  useUnmount(() => {
    closeVehicleActiveScheduleSocket();
  });

  return {
    activeSchedule,
    routes,
    originPoint,
    destinationPoint,
    getActiveScheduleRoute,
    resetScheduleAndRoute,
    sortedTaskRoutesForPolylines,
  };
};

/**
 * スケジュール登録ページのルート検索
 * @param vehicleId
 */
export const useScheduleRegisterRouteSearch = (vehicleId: string) => {
  const { getRouteSearch } = useRouteSearchAPI();
  const { getRouteSearchFromCurrentPosition } =
    useRouteSearchFromCurrentPositionAPI();
  const { getPartialRoutesSearch } = usePartialRouteSearchAPI();
  const { getPartialRouteSearchFromCurrentPosition } =
    usePartialRouteSearchFromCurrentPositionAPI();
  const { getSchedules } = useSchedulesAPI();
  const { getSchedule } = useScheduleAPI();
  const { getRoute } = useRouteAPI();
  const editPlaces = useRecoilValue(editPlacesSelector);
  const setSearchedRoutes = useSetRecoilState(searchedRoutesAtom);
  const selectedScheduleType = useRecoilValue(selectedScheduleTypeAtom);
  const setEditBasicPlaces = useSetRecoilState(editBasicPlacesAtom);
  const setEditLoopPlaces = useSetRecoilState(editLoopPlacesAtom);
  const setEditWeekTimetable = useSetRecoilState(editWeekTimetableAtom);
  const selectedDayOfWeek = useRecoilValue(selectedDayOfWeekAtom);
  const selectedDayOfWeekTimetable = useRecoilValue(
    selectedDayOfWeekTimetableAtom,
  );
  const validTimetableStartTimes = useRecoilValue(
    validTimetableStartTimesSelector,
  );
  const isTimetableStartTimeAdjusted = useRecoilValue(
    isTimetableStartTimeAdjustedAtom,
  );
  const resetIsTimetableStartTimeAdjusted = useResetRecoilState(
    isTimetableStartTimeAdjustedAtom,
  );
  const prevVehicleId = usePrevious(vehicleId);

  const isLoading = useRef(false);

  const removePartialRouteSearchErrors = useRecoilCallback(
    ({ snapshot }) =>
      async (index: number) => {
        const selectedScheduleType = await snapshot.getPromise(
          selectedScheduleTypeAtom,
        );
        const selectedDayOfWeek = await snapshot.getPromise(
          selectedDayOfWeekAtom,
        );

        if (selectedScheduleType === 'basic') {
          setEditBasicPlaces((prevState) =>
            prevState.map((place, i) => {
              if (i === index) {
                return {
                  ...place,
                  partialRouteSearchErrorIndexs: [],
                };
              }
              return place;
            }),
          );
        }
        if (selectedScheduleType === 'loop') {
          setEditLoopPlaces((prevState) =>
            prevState.map((place, i) => {
              if (i === index) {
                return {
                  ...place,
                  partialRouteSearchErrorIndexs: [],
                };
              }
              return place;
            }),
          );
        }
        if (selectedScheduleType === 'timetable') {
          setEditWeekTimetable((prevState) => {
            const targetDayData = { ...prevState[selectedDayOfWeek] };
            targetDayData.routes = targetDayData.routes.map((place, i) => {
              if (i === index) {
                return {
                  ...place,
                  partialRouteSearchErrorIndexs: [],
                };
              }
              return place;
            });
            return {
              ...prevState,
              [selectedDayOfWeek]: targetDayData,
            };
          });
        }
      },
    [setEditBasicPlaces, setEditLoopPlaces, setEditWeekTimetable],
  );

  const getPartialRoutes = useRecoilCallback(
    ({ snapshot }) =>
      async (
        vehicleId: string,
        originId: number,
        destinationId: number,
        startTime: string,
        laneIds: string[] = [],
        routeIndex: number,
      ) => {
        //
        const selectedScheduleType = await snapshot.getPromise(
          selectedScheduleTypeAtom,
        );
        const selectedViaPoint = await snapshot.getPromise(
          selectedViaPointAtom,
        );
        const selectedDayOfWeek = await snapshot.getPromise(
          selectedDayOfWeekAtom,
        );
        const showError = selectedViaPoint.isEditing
          ? selectedViaPoint.routeIndex === routeIndex
          : true;

        // destination ID が無効の場合は中断
        if (
          !originId ||
          Number.isNaN(originId) ||
          !destinationId ||
          Number.isNaN(destinationId)
        ) {
          return null;
        }

        if (laneIds.length > 0) {
          const paramsList: PartialRouteSearchRequestParams[] = [];

          // 片道 or 巡回の最初の地点が現在位置
          const isFirstRouteFromCurrentPosition = originId === -1;

          const firstRouteRes = isFirstRouteFromCurrentPosition
            ? await getPartialRouteSearchFromCurrentPosition(
                vehicleId,
                {
                  destination_lanelet_id: Number(laneIds[0]),
                  start_time: startTime,
                },
                showError,
              )
            : null;

          if (
            isFirstRouteFromCurrentPosition &&
            isNullOrUndefined(firstRouteRes)
          ) {
            setEditBasicPlaces((prevState) =>
              prevState.map((place, i) =>
                i === 0
                  ? { ...place, partialRouteSearchErrorIndexs: [0] }
                  : place,
              ),
            );
            return null;
          }

          laneIds.forEach((laneId, i) => {
            if (i === 0 && originId !== -1) {
              // 片道 or 巡回の最初の地点が前のスケジュール地点
              paramsList.push({
                origin_point: originId,
                destination_lanelet_id: Number(laneId),
                start_time: startTime,
              });
            }

            if (!isNullOrUndefined(firstRouteRes)) {
              // 現在位置からのルートサーチ結果がある場合その分の秒数を足しておく
              startTime = DateTime.fromISO(startTime)
                .plus({ seconds: firstRouteRes.eta_sec })
                .toJSDate()
                .toISOString();
            }

            if (i === laneIds.length - 1) {
              // 最後の Lane ID -> 目的地
              paramsList.push({
                origin_lanelet_id: Number(laneIds.concat().slice(-1)[0]),
                destination_point: destinationId,
                start_time: startTime,
              });
            } else {
              paramsList.push({
                origin_lanelet_id: Number(laneId),
                destination_lanelet_id: Number(laneIds[i + 1]),
                start_time: startTime,
              });
            }
          });

          const partialRouteRes = await getPartialRoutesSearch(
            paramsList,
            showError,
          );

          if (partialRouteRes.length === 0) {
            return null;
          }

          if (firstRouteRes) {
            partialRouteRes.unshift(firstRouteRes);
          }

          if (partialRouteRes.some((res) => res === null)) {
            const searchErrorIndexes: number[] = [];
            partialRouteRes.forEach((res, i) => {
              if (!res) {
                searchErrorIndexes.push(i);
              }
            });

            if (selectedScheduleType === 'basic') {
              setEditBasicPlaces((prevState) => {
                return prevState.map((place, i) => {
                  const returnPlace = { ...place };
                  if (i === routeIndex) {
                    returnPlace.partialRouteSearchErrorIndexs =
                      searchErrorIndexes;
                  }
                  return returnPlace;
                });
              });
            }
            if (selectedScheduleType === 'loop') {
              setEditLoopPlaces((prevState) => {
                return prevState.map((place, i) => {
                  const returnPlace = { ...place };
                  if (i === routeIndex) {
                    returnPlace.partialRouteSearchErrorIndexs =
                      searchErrorIndexes;
                  }
                  return returnPlace;
                });
              });
            }
            if (selectedScheduleType === 'timetable') {
              setEditWeekTimetable((prevState) => {
                const targetDayData = { ...prevState[selectedDayOfWeek] };
                targetDayData.routes = targetDayData.routes.map((place, i) => {
                  const returnPlace = { ...place };
                  if (i === routeIndex) {
                    returnPlace.partialRouteSearchErrorIndexs =
                      searchErrorIndexes;
                  }
                  return returnPlace;
                });
                return {
                  ...prevState,
                  [selectedDayOfWeek]: targetDayData,
                };
              });
            }
            return null;
          }

          const returnRoute: SearchedPartialRoutes = {
            eta_sec: 0,
            distance_meters: 0,
            points: [],
            partial_route_ids: [],
          };

          partialRouteRes.forEach((partialRoute) => {
            if (!partialRoute) return;
            returnRoute.eta_sec += partialRoute.eta_sec;
            returnRoute.distance_meters += partialRoute.distance_meters;
            returnRoute.points = [
              ...returnRoute.points,
              ...partialRoute.points,
            ];
            returnRoute.partial_route_ids = [
              ...returnRoute.partial_route_ids,
              partialRoute.partial_route_id,
            ];
          });

          await removePartialRouteSearchErrors(routeIndex);

          return returnRoute;
        }

        return null;
      },
    [
      getPartialRoutesSearch,
      getPartialRouteSearchFromCurrentPosition,
      setEditBasicPlaces,
      setEditLoopPlaces,
      setEditWeekTimetable,
      removePartialRouteSearchErrors,
    ],
  );

  useEffect(() => {
    if (prevVehicleId !== vehicleId) {
      setSearchedRoutes([]);
      return;
    }
    if (!vehicleId || isLoading.current) return;
    if (selectedScheduleType === 'loop') {
      if (editPlaces.length < 3) {
        // 巡回の場合は開始地点を含んだ停車地点が3つ未満の場合は処理を中断
        setSearchedRoutes([]);
        return;
      }
    } else {
      if (editPlaces.length < 2) {
        // 片道の場合は開始地点を含んだ停車地点が1つの場合は処理を中断
        // 運行ダイヤの場合は停車地点が1つの場合は処理を中断
        setSearchedRoutes([]);
        return;
      }
    }
    if (selectedScheduleType === 'timetable' && !validTimetableStartTimes) {
      // 運行ダイヤの場合に全ての停車地点の予定開始時刻が昇順でない場合処理を中断
      setSearchedRoutes([]);
      return;
    }
    (async () => {
      if (
        selectedScheduleType === 'timetable' &&
        !!selectedDayOfWeekTimetable.scheduleId
      ) {
        isLoading.current = true;
        // タイムテーブルの登録済みデータが有る場合はスケジュール取得後、ルートIDからルートを取得する
        const schedulesRes = await getSchedules(vehicleId);

        if (isNullOrUndefined(schedulesRes)) {
          setSearchedRoutes([]);
          isLoading.current = false;
          return;
        }

        const targetSchedule = schedulesRes.schedules.find(
          (schedule) =>
            schedule.schedule_id === selectedDayOfWeekTimetable.scheduleId,
        );

        if (isNullOrUndefined(targetSchedule)) {
          setSearchedRoutes([]);
          isLoading.current = false;
          return;
        }

        const scheduleRes = await getSchedule(
          selectedDayOfWeekTimetable.scheduleId,
        );

        if (!scheduleRes) {
          isLoading.current = false;
          return;
        }

        const routeIds = scheduleRes.tasks.map((task) => task.route_ids[0]);

        const routeRes = await getRoute(routeIds);
        setSearchedRoutes(
          routeRes.map((route) => ({
            id: '',
            partial_route_ids: [],
            route,
          })),
        );
        isLoading.current = false;
        return;
      }

      if (selectedScheduleType === 'timetable') {
        const isTimetableStartTimeAdjustedArr = Object.keys(
          isTimetableStartTimeAdjusted,
        );
        const isAdjusted = isTimetableStartTimeAdjustedArr.every(
          (key) => isTimetableStartTimeAdjusted[key] === true,
        );
        if (!isAdjusted) return;
        if (
          isTimetableStartTimeAdjustedArr.length === 1 &&
          editPlaces.length > 2
        ) {
          return;
        }
      }

      isLoading.current = true;

      const searchedRoutes: SearchedRoutes = [];
      let index = 0;
      const requestNum = editPlaces.length;
      const initialTime = DateTime.now();

      const searialRequest = async () => {
        const place = editPlaces[index];

        // 開始時刻
        // 片道・巡回の場合は現在時刻 + 前のルートの所要時間とする
        let startTime = initialTime
          .plus({
            seconds: searchedRoutes.reduce((prev, current) => {
              if (current && current.route) {
                return prev + current.route.eta_sec;
              }
              return prev;
            }, 0),
          })
          .toJSDate()
          .toISOString();

        if (selectedScheduleType === 'timetable') {
          // 運行ダイヤの場合は各ルートの開始時刻を設定するためその時刻とする
          const data = place as TimetablePlaceData;
          const dataStartTime = DateTime.fromISO(data.startTime);

          if (dataStartTime.isValid) {
            // 曜日の index を取得
            // luxon は 0 = sunday だが、 実装では 0 = monday となるため +1 する
            const dayIndex = Object.keys(
              en.common.day_of_week.original,
            ).findIndex((day) => day === selectedDayOfWeek);
            // 曜日をセット
            const weekday = dataStartTime.set({ weekday: dayIndex + 1 });
            const today = DateTime.now();
            if (weekday.startOf('day') < today.startOf('day')) {
              // 今日より前の場合、次の週に設定
              startTime = weekday.plus({ weeks: 1 }).toJSDate().toISOString();
            } else {
              startTime = weekday.toJSDate().toISOString();
            }
          }
        }

        if (
          (selectedScheduleType === 'basic' ||
            selectedScheduleType === 'loop') &&
          index === 0
        ) {
          // 片道 or 巡回 最初のルート検索
          let res: Route | null;
          if ((place as BasicPlace).isManual) {
            // 手動運転登録されている場合
            searchedRoutes.push(null);
          } else {
            const originPointId = editPlaces[0].point_id;
            if (place.viaPointLaneletIds.length > 0) {
              // 経由地あり
              res = await getPartialRoutes(
                vehicleId,
                originPointId,
                editPlaces[1].point_id,
                startTime,
                place.viaPointLaneletIds,
                0,
              );
            } else {
              // 経由地なし
              if (originPointId === -1) {
                res = await getRouteSearchFromCurrentPosition(vehicleId, {
                  destination_point: editPlaces[1].point_id,
                  start_time: startTime,
                });
              } else {
                res = await getRouteSearch({
                  origin_point: originPointId,
                  destination_point: editPlaces[1].point_id,
                  start_time: startTime,
                });
              }
            }
            if (res) {
              const data: SearchedRoute = {
                route: res,
                partial_route_ids:
                  (res as SearchedPartialRoutes).partial_route_ids ?? [],
              };
              await removePartialRouteSearchErrors(0);
              searchedRoutes.push(data);
            } else {
              searchedRoutes.push(null);
            }
          }
        } else {
          if (selectedScheduleType !== 'loop' && index === requestNum - 1) {
            searchedRoutes.push(null);
          } else if (
            selectedScheduleType === 'basic' &&
            (place as BasicPlace).isManual
          ) {
            // 手動運転の場合
            searchedRoutes.push(null);
          } else {
            const nextPlace =
              index === editPlaces.length - 1
                ? editPlaces[1]
                : editPlaces[index + 1];
            if (nextPlace) {
              let res: Route | null = null;

              // ない場合はルートサーチAPIをリクエスト
              if (place.viaPointLaneletIds.length) {
                // 経由地あり
                res = await getPartialRoutes(
                  vehicleId,
                  place.point_id,
                  nextPlace.point_id,
                  startTime,
                  place.viaPointLaneletIds,
                  index,
                );
              } else {
                // 経由地なし
                res = await getRouteSearch({
                  origin_point: place.point_id,
                  destination_point: nextPlace.point_id,
                  start_time: startTime,
                });
              }
              if (res) {
                // ルートサーチ結果がある場合
                const data: SearchedRoute = {
                  route: res,
                  partial_route_ids:
                    (res as SearchedPartialRoutes).partial_route_ids ?? [],
                };
                await removePartialRouteSearchErrors(index);
                searchedRoutes.push(data);
              } else {
                searchedRoutes.push(null);
              }
            } else {
              searchedRoutes.push(null);
            }
          }
        }
        if (index < requestNum - 1) {
          index += 1;
          await searialRequest();
        }
      };

      await searialRequest();

      if (selectedScheduleType !== 'loop') {
        // 巡回以外は配列の最後がかならず null となるので最後を削除
        searchedRoutes.pop();
      }
      setSearchedRoutes(searchedRoutes);
      resetIsTimetableStartTimeAdjusted();
      isLoading.current = false;
    })();
  }, [
    getPartialRoutes,
    editPlaces,
    selectedScheduleType,
    prevVehicleId,
    vehicleId,
    getRouteSearch,
    getRouteSearchFromCurrentPosition,
    getSchedules,
    getSchedule,
    getRoute,
    setSearchedRoutes,
    selectedDayOfWeekTimetable,
    setEditBasicPlaces,
    setEditLoopPlaces,
    setEditWeekTimetable,
    selectedDayOfWeek,
    removePartialRouteSearchErrors,
    validTimetableStartTimes,
    isTimetableStartTimeAdjusted,
    resetIsTimetableStartTimeAdjusted,
  ]);
};
