import {
  Box,
  Icon,
  IconButton as MuiIconButton,
  Typography,
  styled,
  colors,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  Column,
  Table,
} from 'react-virtualized';
import DataCell, { DataCellBox } from '../../atoms/DataCell';
import { DateTime } from 'luxon';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import type { HistoryData } from '@components/pages/DrivingHistory/types';
import { useTranslation } from 'react-i18next';
import { ProbeNotGenerated } from '..';
import type { MeasuredCellParent } from 'react-virtualized/dist/es/CellMeasurer';
import type { SetterOrUpdater } from 'recoil';
import type { EventData } from '@data/datahub/event/types';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 65,
});

type DateProps = {
  date?: string;
};

const Date: React.FC<DateProps> = React.memo(({ date }: DateProps) => {
  const getDate = useCallback((value: string) => {
    if (isNullOrUndefined(value)) return null;
    return DateTime.fromISO(value).toFormat('yyyy/MM/dd');
  }, []);

  const getTime = useCallback((value: string) => {
    if (isNullOrUndefined(value)) return null;
    return DateTime.fromISO(value).toFormat('HH:mm:ss');
  }, []);

  if (!date) return null;

  return (
    <Box>
      <Typography variant="caption" color="textSecondary">
        {getDate(date)}
      </Typography>
      <Typography>{getTime(date)}</Typography>
    </Box>
  );
});

Date.displayName = 'Date';

type Props = {
  data: HistoryData;
  isProbeGenerated: boolean;
  setData: SetterOrUpdater<HistoryData>;
  height: number;
};

const FoaList: React.FC<Props> = ({
  data,
  setData,
  isProbeGenerated,
  height,
}: Props) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      // 562
      {
        label: 'No',
        dataKey: 'no',
        width: 56,
      },
      {
        label: t('common.date.time'),
        dataKey: 'log_time_jst',
        width: 130,
      },
      {
        label: 'Map',
        dataKey: 'map',
        width: 80,
      },
      {
        label: t('common.general.contents'),
        dataKey: 'contents',
        width: 302,
      },
    ],
    [t],
  );

  const handleClickMapIcon = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const index = Number(e.currentTarget.dataset.index);
      const targetEvent = data.foaEvents[index];

      if (
        isNullOrUndefined(targetEvent.lat) ||
        isNullOrUndefined(targetEvent.lng)
      ) {
        return;
      }

      setData((prevState) => ({
        ...prevState,
        selectedFoaEvent: {
          no: index + 1,
          data: targetEvent,
          selectedTime: DateTime.now().toSeconds().toString(),
        },
      }));
    },
    [setData, data.foaEvents],
  );

  const headerRenderer = useCallback(
    ({ label }: { label: React.ReactNode }) => {
      return (
        <DataCell variant="head" align="left" component="div">
          <span>{label}</span>
        </DataCell>
      );
    },
    [],
  );

  const cellRenderer = useCallback(
    ({
      dataKey,
      rowData,
      rowIndex,
      parent,
    }: {
      dataKey: string;
      rowData: EventData;
      rowIndex: number;
      parent: MeasuredCellParent;
    }) => {
      const getCellData = () => {
        if (dataKey === 'no') return <DataCellBox>{rowIndex + 1}</DataCellBox>;
        if (dataKey === 'log_time_jst') {
          return (
            <DataCellBox data-testid="foa_time">
              <Date date={rowData.timestamp} />
            </DataCellBox>
          );
        }
        if (dataKey === 'map') {
          const disabled =
            isNullOrUndefined(rowData.lat) || isNullOrUndefined(rowData.lng);
          return (
            <DataCellBox>
              <Tooltip
                arrow
                placement="top"
                title={
                  disabled ? t('driving_history.foa_list.location_unknown') : ''
                }
              >
                <span>
                  <IconButton
                    size="small"
                    disabled={disabled}
                    data-index={rowIndex}
                    onClick={handleClickMapIcon}
                    data-testid="map_icon"
                  >
                    <Icon>room</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            </DataCellBox>
          );
        }
        if (dataKey === 'contents') {
          if (isNullOrUndefined(rowData.payload)) return;
          return (
            <DataCellBox>
              <Box>
                <Box
                  key={rowData.timestamp}
                  sx={{ mb: 2, '&:last-of-type': { mb: 0 } }}
                >
                  <Typography data-testid="foa_app">
                    app&nbsp;:&nbsp;{rowData.payload.app ?? '-'}
                  </Typography>
                  <Typography data-testid="foa_key">
                    key&nbsp;:&nbsp;{rowData.payload.key ?? '-'}
                  </Typography>
                  <Typography data-testid="foa_value">
                    value&nbsp;:&nbsp;{rowData.payload.value ?? '-'}
                  </Typography>
                </Box>
              </Box>
            </DataCellBox>
          );
        }
      };

      if (dataKey === 'contents') {
        return (
          <CellMeasurer
            key={dataKey}
            cache={cache}
            parent={parent}
            columnIndex={0}
            rowIndex={rowIndex}
          >
            <DataCell component="div" sx={{ border: 'none' }}>
              {getCellData()}
            </DataCell>
          </CellMeasurer>
        );
      }

      return (
        <DataCell component="div" sx={{ border: 'none' }}>
          {getCellData()}
        </DataCell>
      );
    },
    [handleClickMapIcon, t],
  );

  if (!isProbeGenerated) {
    return (
      <Box width="100%" height={height}>
        <ProbeNotGenerated />
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      height={height}
      sx={{
        '.ReactVirtualized__Table__row': {
          borderBottom: `solid 1px ${colors.grey[300]}`,
        },
      }}
    >
      <AutoSizer>
        {({ width, height }) => (
          <Table
            width={width}
            height={height}
            headerHeight={45}
            rowCount={data.foaEvents.length}
            rowGetter={({ index }) => data.foaEvents[index]}
            rowHeight={cache.rowHeight}
            deferredMeasurementCache={cache}
            columns={columns}
          >
            {columns.map(({ dataKey, ...other }) => (
              <Column
                key={dataKey}
                dataKey={dataKey}
                headerRenderer={(headerProps) =>
                  headerRenderer({
                    label: headerProps.label,
                  })
                }
                cellRenderer={({ dataKey, rowData, rowIndex, parent }) =>
                  cellRenderer({
                    dataKey,
                    rowData,
                    rowIndex,
                    parent,
                  })
                }
                {...other}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    </Box>
  );
};

export default React.memo(FoaList);

const IconButton = styled(MuiIconButton)`
  padding: 0;
`;
