import React, { useCallback } from 'react';
import { InfoBox, Marker } from '@react-google-maps/api';
import { Box, Grid, IconButton, Typography, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { mapMenuAtom } from '@data/ui/states';
import { useTranslation } from 'react-i18next';
import FeatureToggle, { ToggleOn } from '../FeatureToggle';
import { FEATURE_SCOPES } from '@data/auth';
import type { Place } from '@data/fms/place/types';

type Props = {
  data: Place;
};

const SIZE = 32;
const CENTER = SIZE * 0.5;
const INFO_SIZE = 220;

const StoppableMarker: React.FC<Props> = ({ data }: Props) => {
  const [mapMenu, setMapMenu] = useRecoilState(mapMenuAtom);
  const { t } = useTranslation();

  const handleOpenInfo = useCallback(() => {
    setMapMenu((prevState) => ({
      ...prevState,
      stopPointNames: {
        ...prevState.stopPointNames,
        [data.point_id]: true,
      },
    }));
  }, [setMapMenu, data.point_id]);

  const handleCloseInfo = useCallback(() => {
    setMapMenu((prevState) => ({
      ...prevState,
      stopPointNames: {
        ...prevState.stopPointNames,
        [data.point_id]: false,
      },
    }));
  }, [setMapMenu, data.point_id]);

  return (
    <>
      <Marker
        zIndex={100}
        position={data.location}
        icon={{
          url: '/assets/img/maps/stop_point.png',
          scaledSize: new window.google.maps.Size(SIZE, SIZE),
          anchor: new window.google.maps.Point(CENTER, CENTER),
        }}
        onClick={handleOpenInfo}
      />
      {mapMenu.stopPointNames[data.point_id] && (
        <InfoBox
          position={data.location as unknown as google.maps.LatLng}
          options={{
            pixelOffset: new window.google.maps.Size(-INFO_SIZE * 0.5, -2),
            maxWidth: INFO_SIZE,
            alignBottom: true,
            disableAutoPan: true,
            closeBoxURL: '',
          }}
        >
          <Box width={INFO_SIZE} pb={4} textAlign="center">
            <Wrapper>
              <FeatureToggle
                type="featureScope"
                scope={FEATURE_SCOPES.GoalModification}
              >
                <ToggleOn>
                  {data.allow_goal_modification && (
                    <SholderLabel>
                      {t('common.fms.place.allow_goal_modification')}
                    </SholderLabel>
                  )}
                </ToggleOn>
              </FeatureToggle>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={1}
                data-testid={data.point_id}
              >
                <Grid
                  item
                  sx={{ width: 'calc(100% - 30px)', wordBreak: 'break-all' }}
                >
                  <Typography variant="body2" align="left">
                    {data.name}
                  </Typography>
                </Grid>
                <Grid item sx={{ width: '30px' }}>
                  <IconButton
                    size="small"
                    onClick={handleCloseInfo}
                    sx={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            </Wrapper>
          </Box>
        </InfoBox>
      )}
    </>
  );
};

export default React.memo(StoppableMarker);

const Wrapper = styled(Box)`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  padding: 4px 10px;
  box-sizing: border-box;
  position: relative;
  pointer-events: none;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -7px;
    border: 7px solid transparent;
    border-top: 7px solid rgba(255, 255, 255, 0.8);
  }
`;

const SholderLabel = styled(Typography)`
  font-size: 10px;
  color: white;
  text-align: left;
  background: ${({ theme }) => theme.palette.primary.main};
  line-height: 1;
  padding: ${({ theme }) => theme.spacing(1)};
  border-radius: 4px;
  margin-top: 4px;
`;
