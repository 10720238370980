import { type SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { placesAtom, placesLatLngsSelector } from './states';
import type { LatLng, Place } from './types';

export const usePlaces = (): {
  places: Place[];
  setPlaces: SetterOrUpdater<Place[]>;
  placesLatLngs: LatLng[];
} => {
  const [places, setPlaces] = useRecoilState(placesAtom);
  const placesLatLngs = useRecoilValue(placesLatLngsSelector);
  return {
    places,
    setPlaces,
    placesLatLngs,
  };
};
