import { useWeatherForecastAPI } from '@api/datahub/weather/getWeatherForecast';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { useCallback } from 'react';
import { atom, selector, useSetRecoilState } from 'recoil';
import type { WeatherForecast, WeatherLocation } from './types';

/**
 * 天気情報地点
 */
export const weatherLocationAtom = atom<WeatherLocation | null>({
  key: 'weatherLocationAtom',
  default: null,
});

/**
 * 天気予報情報
 */
export const weatherForecastsAtom = atom<WeatherForecast[]>({
  key: 'weatherForecastsAtom',
  default: [],
});

/**
 * 天気情報が取得可能かどうか
 */
export const enableWeatherInfoSelector = selector({
  key: 'enableWeatherInfoSelector',
  get: ({ get }) => {
    const location = get(weatherLocationAtom);
    return !isNullOrUndefined(location);
  },
});

export const useWeatherInfo = () => {
  const { getWeatherForecast } = useWeatherForecastAPI();
  const setWeatherForecasts = useSetRecoilState(weatherForecastsAtom);

  const getWeatherInfo = useCallback(
    async (locationId?: string) => {
      const forecastRes = await getWeatherForecast(locationId);
      setWeatherForecasts(forecastRes);
    },
    [getWeatherForecast, setWeatherForecasts],
  );

  return getWeatherInfo;
};
