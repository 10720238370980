import { usePostAuthorizationAPI } from '@api/auth';
import {
  FEATURE_SCOPES,
  SCOPES,
  useHasFeatureScope,
  useHasScope,
} from '@data/auth';
import type { IconButtonProps } from '@mui/material';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  Typography,
  styled,
} from '@mui/material';
import { Mic, MicOff } from '@mui/icons-material';
import { MediaClient } from '@tier4/webauto-media-client';
import type { RemoteCamera } from '@tier4/webauto-media-client/dist/RemoteDevices';
import isNullOrUndefined from '@utils/isNullOrUndefined';
import { isEqual } from 'lodash';
import type { HTMLAttributes } from 'react';
import React, {
  useRef,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { useMount, usePrevious, useUnmount } from 'react-use';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';
import { environmentAtom } from '@data/fms/environment/states';
import { remoteMonitorConfigAtom } from '@data/fms/vehicle/states';

type CameraProps = HTMLAttributes<HTMLDivElement> & {
  camera: RemoteCamera;
  component?: 'td' | 'div';
  children?: React.ReactNode;
};

const Camera: React.FC<CameraProps> = ({
  camera,
  component = 'div',
  children,
}: CameraProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  /**
   * マウント時
   * カメラに接続しVideoに反映
   */
  useMount(async () => {
    if (isNullOrUndefined(camera)) return;
    const stream: MediaStream = await camera.connect();
    if (!isNullOrUndefined(videoRef.current)) {
      videoRef.current.srcObject = stream;
      videoRef.current.play().catch((error) => console.log(error));
    }
  });

  /**
   * アンマウント時
   */
  useUnmount(async () => {
    if (!isNullOrUndefined(videoRef.current)) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (isNullOrUndefined(stream)) return;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
    if (!isNullOrUndefined(camera)) {
      await camera.disconnect();
    }
  });

  if (isNullOrUndefined(camera)) return null;

  if (component === 'td') {
    return (
      <CameraWrapperTd className={camera.tag}>
        <Video ref={videoRef} />
        <Tag>{camera.tag}</Tag>
        {children}
      </CameraWrapperTd>
    );
  }

  return (
    <CameraWrapperDiv className={camera.tag}>
      <Video ref={videoRef} />
      <Tag>{camera.tag}</Tag>
      {children}
    </CameraWrapperDiv>
  );
};

const CameraWrapperDiv = styled('div')`
  position: absolute;
  box-sizing: border-box;
  background-color: black;
  width: 100%;
  height: 100%;
`;

const CameraWrapperTd = styled('td')`
  position: relative;
  box-sizing: border-box;
`;

const Video = styled('video')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
`;

const Tag = styled('p')`
  color: black;
  position: absolute;
  text-transform: capitalize;
  margin: 0;
  line-height: 1;
  padding: 4px;
  user-select: none;
  background-color: rgba(255, 255, 255, 0.5);

  .front-center & {
    bottom: 8px;
    left: calc(50% - 42px);
  }

  .front-left & {
    top: 8px;
    right: 8px;
  }

  .front-right & {
    top: 8px;
    left: 8px;
  }

  .left-mirror & {
    top: 8px;
    right: 8px;
  }

  .right-mirror & {
    top: 8px;
    left: 8px;
  }

  .rear-mirror & {
    top: 8px;
    left: calc(50% - 39px);
  }

  .cabin &,
  .panel & {
    top: 8px;
  }

  .cabin & {
    .camera7 & {
      left: calc(50% - 21px);
    }
  }

  .cabin & {
    .camera8 & {
      right: 8px;
    }
  }

  .panel & {
    .camera8 & {
      left: 8px;
    }
  }

  td & {
    top: 8px !important;
    left: 8px !important;
    bottom: auto !important;
    right: auto !important;
  }
`;

/**
 * カメラの数によってTableの行と列の数を返す
 * @param {Number} cameraNum
 * @return {Object}
 */
const getTableSet = (cameraNum: number) => {
  if (cameraNum === 2) {
    return {
      row: 1,
      column: 2,
    };
  }

  if (cameraNum >= 3 && cameraNum <= 4) {
    return {
      row: 2,
      column: 2,
    };
  }

  if (cameraNum >= 5 && cameraNum <= 6) {
    return {
      row: 2,
      column: 3,
    };
  }

  if (cameraNum >= 7 && cameraNum <= 8) {
    return {
      row: 2,
      column: 4,
    };
  }

  return {
    row: 3,
    column: 4,
  };
};

enum EightCamerasPredefinedTags {
  FrontCenter = 'front-center',
  FrontLeft = 'front-left',
  FrontRight = 'front-right',
  RearMirror = 'rear-mirror',
  LeftMirror = 'left-mirror',
  RightMirror = 'right-mirror',
  Panel = 'panel',
  Cabin = 'cabin',
}

type CallState = null | 'init' | 'ready' | 'calling' | 'talking';
type MicButtonState = 'init' | 'calling' | 'talking';

type Props = {
  cameras: RemoteCamera[];
};

const RemoteMedia: React.FC<Props> = ({ cameras }: Props) => {
  const getToken = usePostAuthorizationAPI();
  const environment = useRecoilValue(environmentAtom);
  const remoteMonitorConfig = useRecoilValue(remoteMonitorConfigAtom);
  const [callState, setCallState] = useState<CallState>(null);
  const prevCallState = usePrevious(callState);
  const [call, setCall] = useState<MediaClient.RemoteCall | null>(null);
  const [calling, setCalling] = useState<MediaClient.Calling | null>(null);
  const [talking, setTalking] = useState<MediaClient.Conversation | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [micButtonState, setMicButtonState] = useState<MicButtonState>('init');
  const getHasFeatureScope = useHasFeatureScope();
  const getHasScope = useHasScope();
  const { t } = useTranslation();

  /**
   * stream track stop
   */
  const stopStreamTracks = useCallback(() => {
    if (!isNullOrUndefined(stream)) {
      stream.getTracks().forEach((t) => t.stop());
    }
    setTalking(null);
    setStream(null);
    setCalling(null);
    setCall(null);
    setMicButtonState('init');
  }, [stream]);

  /**
   * 遠隔通話機能 & 権限があるか
   */
  const hasCallScope = useMemo(
    () =>
      getHasFeatureScope(FEATURE_SCOPES.VoiceCall) && getHasScope(SCOPES.Call),
    [getHasFeatureScope, getHasScope],
  );

  /**
   * 遠隔カメラ機能 & 権限があるか
   */
  const hasCameraScope = useMemo(
    () =>
      getHasFeatureScope(FEATURE_SCOPES.VideoStreaming) &&
      getHasScope([SCOPES.DescribeMediaStatus, SCOPES.ReceiveMediaStream]),
    [getHasFeatureScope, getHasScope],
  );

  /**
   * マイクボタンクリック時
   */
  const handleClickMic = useCallback(() => {
    const cancel = async () => {
      if (!isNullOrUndefined(calling)) await calling.cancel();
    };

    const hangUp = async () => {
      if (!isNullOrUndefined(talking)) await talking.hangUp();
    };

    setCallState((prevState) => {
      if (isNullOrUndefined(prevState)) {
        return 'init';
      }
      if (prevState === 'calling') {
        cancel();
        stopStreamTracks();
        return null;
      }
      if (prevState === 'talking') {
        hangUp();
        stopStreamTracks();
        return null;
      }
      return prevState;
    });
  }, [calling, talking, stopStreamTracks]);

  /**
   * マイクボタン
   */
  const micButton = useMemo(() => {
    if (!hasCallScope) return null;
    return (
      <MicButtonWrapper>
        <MicButton onClick={handleClickMic} state={micButtonState}>
          {micButtonState === 'talking' || micButtonState === 'calling' ? (
            <MicOff />
          ) : (
            <Mic />
          )}
        </MicButton>
        {(micButtonState === 'talking' || micButtonState === 'calling') && (
          <MicButtonStateLabel>
            {t(`remote.call.state.${micButtonState}`)}
          </MicButtonStateLabel>
        )}
      </MicButtonWrapper>
    );
  }, [handleClickMic, micButtonState, hasCallScope, t]);

  /**
   * カメラのタグ一覧
   */
  const tags = useMemo(() => cameras.map((camera) => camera.tag), [cameras]);

  const hasSevenCameras = useMemo(() => {
    const filteredTags = Object.values(EightCamerasPredefinedTags).filter(
      (tag) => tag !== 'panel',
    );
    return isEqual(tags.sort(), filteredTags.sort());
  }, [tags]);

  const hasEightCameras = useMemo(
    () =>
      isEqual(tags.sort(), Object.values(EightCamerasPredefinedTags).sort()),
    [tags],
  );

  /**
   * カメラ配置
   */
  const content = useMemo(() => {
    if (cameras.length === 0 || !hasCameraScope) {
      return (
        <Wrapper>
          <Box pt={6}>
            <Typography align="center" sx={{ color: 'white' }}>
              {t(
                `remote.cameras.${!hasCameraScope ? 'no_scope' : 'no_camera'}`,
              )}
            </Typography>
          </Box>
          {micButton}
        </Wrapper>
      );
    }

    if (cameras.length === 1) {
      return (
        <Wrapper>
          <Camera camera={cameras[0]} />
          {micButton}
        </Wrapper>
      );
    }

    const { row, column } = getTableSet(cameras.length);
    let n = 0;

    return (
      <Wrapper>
        <Table sx={{ height: '100%' }}>
          <TableBody>
            {[...Array(row).keys()].map((i) => (
              <tr key={`row${i}`}>
                {[...Array(column).keys()].map(() => {
                  const camera = cameras[n];
                  n += 1;
                  return (
                    <Camera key={`camera${n}`} camera={camera} component="td" />
                  );
                })}
              </tr>
            ))}
          </TableBody>
        </Table>
        {micButton}
      </Wrapper>
    );
  }, [cameras, micButton, hasCameraScope, t]);

  useEffect(() => {
    (async () => {
      // 機能・権限がない場合は中断
      if (!hasCallScope) return;

      if (prevCallState !== 'init' && callState === 'init') {
        MediaClient.addAuthTokenCallback(async () => {
          const token = await getToken();
          return {
            token: token.accessToken,
            expiredAt: new Date(token.expiry * 1000),
          };
        });
        if (!environment) {
          throw new Error('Failed to get Driving Environment');
        }
        if (!remoteMonitorConfig.vehicle) {
          throw new Error('Failed to get monitored vehicle');
        }
        const callRes = MediaClient.createRemoteCall(
          {
            projectId: environment.project_id,
            environmentId: environment.environment_id,
            vehicleId: remoteMonitorConfig.vehicle.vehicle_id,
            role: 'operator',
          },
          () => {
            console.log('ringing');
          },
        );
        setCall(callRes);
        setCallState('ready');
      }

      if (
        prevCallState !== 'ready' &&
        callState === 'ready' &&
        !isNullOrUndefined(call)
      ) {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true,
        });
        const callingRes = call.call('operator', mediaStream);
        setStream(mediaStream);
        setCalling(callingRes);
        setMicButtonState('calling');
        setCallState('calling');
      }

      if (
        prevCallState !== 'calling' &&
        callState === 'calling' &&
        !isNullOrUndefined(calling)
      ) {
        let talkingRes;
        try {
          talkingRes = await calling.waitForAnswer();
          setMicButtonState('talking');
          setCallState('talking');
        } catch (error) {
          console.log(error);
          if (error instanceof MediaClient.RemoteCall.Canceled) {
            console.log('The call is canceled by the caller');
          } else if (error instanceof MediaClient.RemoteCall.Refused) {
            console.log('The call is refused by the callee');
          } else {
            console.log(error);
          }
          setCallState(null);
          stopStreamTracks();
          talkingRes = null;
        }
        setTalking(talkingRes);

        if (!isNullOrUndefined(talkingRes)) {
          await talkingRes.waitForHangUp();
          stopStreamTracks();
          setCallState(null);
        }
      }

      if (
        prevCallState !== 'talking' &&
        callState === 'talking' &&
        !isNullOrUndefined(talking)
      ) {
        const audio = new Audio();
        try {
          audio.srcObject = talking.stream;
          await audio.play();
        } catch (error) {
          console.log(error);
          stopStreamTracks();
          setCallState(null);
        }
      }
    })();
  }, [
    call,
    calling,
    talking,
    stream,
    callState,
    prevCallState,
    getToken,
    remoteMonitorConfig.vehicle,
    environment,
    hasCallScope,
    stopStreamTracks,
  ]);

  useUnmount(() => {
    calling?.cancel();
    stopStreamTracks();
  });

  if (hasSevenCameras || hasEightCameras) {
    return (
      <Wrapper>
        <CamerasWrapper className={`camera${cameras.length}`}>
          {cameras.map((camera) => {
            if (camera.tag.toLowerCase() === 'cabin') {
              return (
                <Camera key={camera.id} camera={camera}>
                  {micButton}
                </Camera>
              );
            }
            return <Camera key={camera.id} camera={camera} />;
          })}
          <VehicleInfo>{micButton}</VehicleInfo>
        </CamerasWrapper>
      </Wrapper>
    );
  }

  return content;
};

export default React.memo(RemoteMedia);

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  position: relative;
`;

const MicButtonWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 48px;
  height: 48px;
`;

type MicButtonProps = IconButtonProps & {
  state: MicButtonState;
};

const MicButton = styled(IconButton)<MicButtonProps>`
  background-color: rgba(245, 9, 9, 0.5);
  color: white;

  &:hover {
    background-color: rgba(245, 9, 9, 0.7);
  }

  ${({ state }: MicButtonProps) => {
    if (state === 'calling') {
      return css`
        background-color: rgba(255, 160, 0, 0.7);

        &:hover {
          background-color: rgba(255, 160, 0, 0.9);
        }
      `;
    }
    if (state === 'talking') {
      return css`
        background-color: rgba(67, 160, 71, 0.7);

        &:hover {
          background-color: rgba(67, 160, 71, 0.9);
        }
      `;
    }
  }}
`;

const MicButtonStateLabel = styled(Typography)`
  text-align: center;
  color: white;
  width: 80px;
  margin-top: 6px;
  margin-left: calc((48px - 80px) / 2);
  user-select: none;
  pointer-events: none;
  font-size: 0.5rem;
  line-height: 1;
`;

const CamerasWrapper = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;

  .front-center {
    width: 100%;
    height: 25%;
    top: 0;
    left: 0;
  }

  .front-left,
  .front-right {
    width: calc(50% - 80px);
    height: 25%;
    top: 25%;
  }

  .front-left {
    left: 0;
  }

  .front-right {
    right: 0;
  }

  .left-mirror,
  .right-mirror,
  .rear-mirror {
    top: 50%;
    width: 33.333333%;
    height: 20%;
  }

  .left-mirror {
    left: 0;
  }

  .rear-mirror {
    left: 33.3333333%;
  }

  .right-mirror {
    right: 0;
  }

  &.camera7,
  &.camera8 {
    .panel,
    .cabin {
      bottom: 0;
      height: 30%;
    }

    .cabin {
      left: 0;
    }
  }

  &.camera7 {
    .cabin {
      width: 100%;
    }
  }

  &.camera8 {
    .cabin,
    .panel {
      width: 50%;
    }

    .panel {
      right: 0;
    }
  }
`;

const VehicleInfo = styled(Box)`
  position: absolute;
  width: 160px;
  height: 25%;
  top: 25%;
  left: calc(50% - 80px);
  background-color: rgba(255, 255, 255, 0.3);
  background-image: url(/assets/img/maps/remote_vehicle_image.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
