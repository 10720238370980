import isNullOrUndefined from '@utils/isNullOrUndefined';
import { atom, selectorFamily } from 'recoil';

export type Project = {
  id: string;
  created_at: string;
  updated_at: string;
  owner_group_id: string;
  description: string;
  display_name: string;
  is_active: boolean;
};

export type ProjectMember = {
  subject_id: string;
  name: string;
  participated_at: string;
  organization: {
    id: string;
    display_name: string;
  };
};

export const projectAtom = atom<Project | null>({
  key: 'projectState',
  default: null,
});

export const projectsAtom = atom<Project[]>({
  key: 'projectsState',
  default: [],
});

export const projectMembersAtom = atom<ProjectMember[]>({
  key: 'projectMembersAtom',
  default: [],
});

export const memberInfoSelectorFamily = selectorFamily({
  key: 'memberInfoSelector',
  get:
    (subjectId: string) =>
    ({ get }) => {
      if (isNullOrUndefined(subjectId)) return;
      const members = get(projectMembersAtom);
      const targetMember = members.find(
        (member) => member.subject_id === subjectId,
      );
      return targetMember;
    },
});
